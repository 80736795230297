import store from '@/store'
import ApiClient from '@/assets/js/ApiClient.js'
import { i18n } from '@/main.js'

class VsTable {
  constructor ({ api, data }) {
    this.api = api
    this.data = data
    this.team = this.data.team || store.getters['swissunihockey/team']
    this.game = this.data.game || store.getters['swissunihockey/game']
    this.title = this.data.title || i18n.tc('editorjs.vs_table.default_title')
  }

  static get toolbox () {
    return {
      title: i18n.tc('editorjs.vs_table.title'),
      icon: '<svg class="h-10 w-10 p-1 fill-current" xmlns="http://www.w3.org/2000/svg"><text fill="#666" fill-rule="evenodd" font-family="Helvetica" font-size="17"><tspan x="1" y="20">VS</tspan></text></svg>'
    }
  }

  render () {
    this.wrapper = document.createElement('div')
    var title = document.createElement('h6')
    title.innerHTML = this.title
    title.addEventListener('input', (e) => {
      this.title = e.target.innerHTML
    })
    this.wrapper.appendChild(title)
    var table = document.createElement('table')
    table.classList.add('swissunihockey-table', 'swissunihockey-vs-table')
    var tbody = table.createTBody()
    ApiClient.get(`/api/swissunihockey/games?mode=direct&game_id=${this.game.id}&amount=10`).then(response => {
      var games = response.data.data.regions[0].rows
      games = games.filter(match => match.cells[3].text[0].toString() !== '-:-').slice(0, 4)
      var row, cell
      if (games.length === 0) {
        this.wrapper.innerHTML = i18n.tc('editorjs.vs_table.no_data')
        return this.wrapper
      }
      games.forEach(match => {
        const resultStr = match.cells[3].text[0].toString()
        const isHome = (this.team.name === match.cells[1].text[0])
        row = tbody.insertRow(-1)
        this._addCell(row, match.cells[0].text.toString().substring(0, 10))
        // Hometeam
        cell = this._addCell(row, match.cells[1].text[0])
        if (isHome) {
          cell.classList.add('font-bold')
        }
        // Awayteam
        cell = this._addCell(row, match.cells[2].text[0])
        if (!isHome) {
          cell.classList.add('font-bold')
        }
        // Result
        cell = this._addCell(row, resultStr)
        const result = resultStr.split(':').map(Number)
        const cellClass = ((isHome && (result[0] > result[1])) || (!isHome && (result[0] < result[1]))) ? 'bg-green-200' : 'bg-red-200'
        cell.classList.add(cellClass)
      })
    })
    this.wrapper.appendChild(table)
    return this.wrapper
  }

  save (blockContent) {
    return {
      title: this.title,
      game: this.game,
      team: this.team
    }
  }

  _addCell (row, value) {
    var cell = row.insertCell(-1)
    cell.innerHTML = value
    return cell
  }
}

export default VsTable
