<style>
  .loader {
    @apply w-16 h-16 mx-auto mb-2 text-white;
    border: 8px solid #edf2f7;
    border-top: 8px solid #38b2ac;
    border-radius: 50%;
    animation: spin 1.8s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
</style>

<template>
<div v-if="visible" class="pointer-events-none fixed w-full h-full top-0 left-0 flex items-center justify-center z-50">
    <div class="absolute w-full h-full bg-gray-900 dark:bg-black opacity-75"></div>
    <div class="bg-white  w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="py-4 text-center px-6">
        <div class="loader"></div>
        {{message}}
        <div class="shadow w-full bg-gray-200 mt-2">
          <div class="bg-teal-500 text-xs leading-none py-1 text-center text-white" :style="`width: ${progress}%`"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: Boolean,
    message: String,
    progress: {
      type: Number,
      default: 0
    }
  }
}
</script>
