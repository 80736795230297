<style>
  .dropdown-menu {
    @apply rounded;
  }
  .dropdown ul {
    @apply list-none p-0;
  }
  .dropdown ul li a {
    @apply text-gray-800 dark:text-white;
  }
  .dropdown ul li a.active {
    @apply bg-gray-400 dark:bg-gray-700;
  }

  .font-item {
    @apply bg-gray-300 hover:bg-gray-400 py-2 px-4 block whitespace-nowrap cursor-pointer dark:bg-gray-800 dark:text-white dark:hover:bg-gray-700;
  }
</style>
<template>
<div class="w-full">
  <div v-if="showMenu" class="top-0 left-0 z-50 fixed w-full h-screen" @click="showMenu = false"></div>
  <div class="dropdown inline-block relative w-full" @click="showMenu = !showMenu">
    <div class="text-lg bg-gray-300 text-gray-800 dark:bg-gray-700 dark:text-white px-3 h-10 rounded inline-flex items-center justify-between cursor-pointer w-full">
      <div>
      <span :style="'font-family: ' + activeFont + ', serif;'">{{activeFont}}</span>
      </div>
      <svg class="fill-current h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/> </svg>
    </div>
    <ul class="dropdown-menu absolute right-0 left-0 z-50 h-84 overflow-y-scroll shadow-lg mt-1 scrollbar" :class="{ 'hidden': !showMenu }">
      <li v-for="(font, index) in fontFamilys" :key="index"><a :class="{ 'active': isActive(font) }" class="font-item " href="#" @click.stop.prevent="fontChanged(font)" :style="'font-family: ' + font + ', serif;'">{{font}}</a></li>
    </ul>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'

const axiosClient = axios.create({
  baseURL: 'https://www.googleapis.com/webfonts/v1/webfonts'
})

export default {
  name: 'fontSelector',
  data () {
    return {
      apiKey: 'AIzaSyCIZf_Q1fG3oaw1CC3jQJnwHoS7ONul-lg',
      fontFamilysSource: [],
      showMenu: false
    }
  },
  computed: {
    ...mapGetters({ activeFont: 'font' }),
    fontFamilys () {
      return this.fontFamilysSource
    }
  },
  methods: {
    fontChanged (font) {
      this.showMenu = false
      this.$store.commit('updateFont', font)
      this.$emit('onFontChanged', this.activeFont)
    },
    isActive (font) {
      return this.activeFont === font
    }
  },
  mounted () {
    axiosClient.get(`?key=${this.apiKey}&sort=popularity`).then(response => {
      this.fontFamilysSource = response.data.items.map(item => item.family).slice(0, 50)
      this.fontFamilysSource.sort((a, b) => a.localeCompare(b))
      var fonts = ''
      this.fontFamilysSource.forEach(font => {
        fonts = fonts + 'family=' + font + ':wght@400;700&'
      })
      var link = document.createElement('link')
      link.type = 'text/css'
      link.rel = 'stylesheet'
      link.href = 'https://fonts.googleapis.com/css2?' + fonts
      document.head.appendChild(link)
    })
  }
}

</script>
