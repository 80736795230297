import Vue from 'vue'
import Vuex from 'vuex'
import ApiClient from '@/assets/js/ApiClient.js'
import swissunihockey from './modules/swissunihockey'
import router from '../router/index.js'
import dayjs from 'dayjs'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    swissunihockey: swissunihockey
  },
  state: {
    accessToken: localStorage.accessToken !== undefined ? localStorage.accessToken : null,
    refreshToken: localStorage.refreshToken !== undefined ? localStorage.refreshToken : null,
    loading: 0,
    user: null,
    subscription: null,
    initialized: false,
    font: 'Roboto'

  },
  getters: {
    font: state => state.font,
    isLoggedIn: state => state.accessToken !== null,
    isLoading: state => state.loading > 0,
    user: state => state.user,
    subscription: state => state.subscription,
    logo: state => {
      return state.swissunihockey.club ? state.swissunihockey.club.logo_url : ''
    },
    hasValidSubscription: state => {
      return state.user !== null && state.user.stripe_id !== '' && state.subscription.stripe_status === 'active' && (!state.subscription.ends_at || dayjs(state.subscription.ends_at).isAfter(dayjs()))
    },
    tooltip: state => state.tooltip
  },
  mutations: {
    setAccessToken (state, accessToken) {
      state.accessToken = accessToken
      localStorage.setItem('accessToken', accessToken)
    },
    setRefreshToken (state, refreshToken) {
      state.refreshToken = refreshToken
      localStorage.setItem('refreshToken', refreshToken)
    },
    resetState (state) {
      state.swissunihockey.booklet = null
      state.accessToken = null
      state.refreshToken = null
      state.user = null
      state.initialized = false
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')
    },
    addEditor (state, editor) {
      state.editors.push(editor)
    },
    setLoading (state, value) {
      state.loading = state.loading + value
    },
    setInitialized (state, flag) {
      state.initialized = flag
    },
    setUser (state, user) {
      state.user = user
    },
    setSubscription (state, subscription) {
      state.subscription = subscription
    },
    updateFont (state, font) {
      state.font = font
    }
  },
  actions: {
    startLoading ({ commit }) {
      commit('setLoading', 1)
    },
    endLoading ({ commit }) {
      commit('setLoading', -1)
    },
    loadMe ({ commit, dispatch }) {
      var prom = new Promise((resolve) => {
        ApiClient.get('api/user').then(response => {
          commit('setUser', response.data.user)
        }).finally(() => {
          ApiClient.get('api/user/subscription').then(response => {
            commit('setSubscription', response.data)
            resolve()
          })
        })
      })
      return prom
    },
    logoutUser ({ commit, dispatch }) {
      dispatch('swissunihockey/resetClub')
      commit('resetState')
      if (router.currentRoute.path !== '/') {
        router.push('/')
      }
    },
    updateAccessToken ({ commit }, accessToken) {
      commit('setAccessToken', accessToken)
    },
    updateRefreshToken ({ commit }, refreshToken) {
      commit('setRefreshToken', refreshToken)
    },
    async initialize ({ commit, dispatch, state }) {
      dispatch('startLoading')
      await dispatch('loadMe')
      await dispatch('swissunihockey/fetchBooklets')
      await dispatch('swissunihockey/fetchClubs')
      await dispatch('swissunihockey/fetchLeagues')
      await dispatch('swissunihockey/resetGame')
      await dispatch('swissunihockey/updateClubById', state.user.club_id)

      var prom = new Promise((resolve) => {
        dispatch('endLoading')
        commit('setInitialized', true)
        resolve()
      })
      return prom
    }
  }
})
