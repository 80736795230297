
import store from '@/store'
require('./index.css').toString()

class TemplateMarker {
  /**
   * Class name for term-tag
   *
   * @type {string}
   */
  static get CSS () {
    return 'cdx-template-marker'
  }

  static get Options () {
    return [
      { text: 'Heimteam', value: 'HOMETEAM' },
      { text: 'Auswärtsteam', value: 'AWAYTEAM' },
      { text: 'Spielort', value: 'GAMEPLACE' },
      { text: 'Datum & Zeit', value: 'GAMETIME' },
      { text: 'Liga', value: 'LEAGUE' }
    ]
  }

  /**
   * @param {{api: object}}  - Editor.js API
   */
  constructor ({ api }) {
    this.api = api
    this.team = store.getters['swissunihockey/team']
    this.awayTeam = store.getters['swissunihockey/awayTeam']
    this.leagueById = store.getters['swissunihockey/leagueById']
    this.game = store.getters['swissunihockey/game']

    /**
     * Toolbar Button
     *
     * @type {HTMLElement|null}
     */
    this.button = null

    /**
     * Tag represented the term
     *
     * @type {string}
     */
    this.tag = 'DIV'

    /**
     * CSS classes
     */
    this.iconClasses = {
      base: this.api.styles.inlineToolButton,
      active: this.api.styles.inlineToolButtonActive
    }
  }

  /**
   * Specifies Tool as Inline Toolbar Tool
   *
   * @return {boolean}
   */
  static get isInline () {
    return true
  }

  /**
   * Create button element for Toolbar
   *
   * @return {HTMLElement}
   */
  render () {
    this.button = document.createElement('button')
    this.button.type = 'button'
    this.button.classList.add(this.iconClasses.base)
    this.button.innerHTML = this.toolboxIcon

    return this.button
  }

  /**
   * Wrap/Unwrap selected fragment
   *
   * @param {Range} range - selected fragment
   */
  surround (range) {
    if (!range) {
      return
    }

    const termWrapper = this.api.selection.findParentTag(this.tag, TemplateMarker.CSS)

    /**
     * If start or end of selection is in the highlighted block
     */
    if (termWrapper) {
      this.unwrap(termWrapper)
    } else {
      this.wrap(range)
    }
  }

  /**
   * Wrap selection with term-tag
   *
   * @param {Range} range - selected fragment
   */
  wrap (range) {
    /**
     * Create a wrapper for highlighting
     */
    const marker = document.createElement(this.tag)

    marker.classList.add(TemplateMarker.CSS)
    marker.dataset.template = TemplateMarker.Options[0].value
    /**
     * SurroundContent throws an error if the Range splits a non-Text node with only one of its boundary points
     * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Range/surroundContents}
     *
     * // range.surroundContents(span)
     */
    marker.appendChild(range.extractContents())
    this.renderTag(marker, TemplateMarker.Options[0].value)
    range.insertNode(marker)

    /**
     * Expand (add) selection to highlighted block
     */
    this.api.selection.expandToTag(marker)
  }

  /**
   * Unwrap term-tag
   *
   * @param {HTMLElement} termWrapper - term wrapper tag
   */
  unwrap (termWrapper) {
    /**
     * Expand selection to all term-tag
     */
    this.api.selection.expandToTag(termWrapper)

    const sel = window.getSelection()
    const range = sel.getRangeAt(0)

    const unwrappedContent = range.extractContents()

    /**
     * Remove empty term-tag
     */
    termWrapper.parentNode.removeChild(termWrapper)

    /**
     * Insert extracted content
     */
    range.insertNode(unwrappedContent)

    /**
     * Restore selection
     */
    sel.removeAllRanges()
    sel.addRange(range)
  }

  /**
   * Check and change Term's state for current selection
   */
  checkState () {
    const termTag = this.api.selection.findParentTag(this.tag, TemplateMarker.CSS)

    this.button.classList.toggle(this.iconClasses.active, !!termTag)

    const state = !!termTag
    if (state) {
      this.showActions(termTag)
    } else {
      this.hideActions()
    }
  }

  /**
   * Get Tool icon's SVG
   * @return {string}
   */
  get toolboxIcon () {
    return '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path d="M10.606 0h-10.606v10.609l13.393 13.391 10.607-10.606-13.394-13.394zm-7.02 6.414c-.782-.785-.781-2.047 0-2.83.782-.782 2.049-.779 2.829-.001.783.783.782 2.048 0 2.831-.783.781-2.046.781-2.829 0zm9.807 14.757l-8.484-8.484 7.778-7.778 8.486 8.485-7.78 7.777zm3.534-6.36l-5.656-5.656.707-.709 5.656 5.657-.707.708zm-1.414 1.414l-5.656-5.656.707-.707 5.656 5.656-.707.707zm-3.535-.707l-3.534-3.536.707-.706 3.535 3.535-.708.707z"/></svg>'
  }

  /**
   * Sanitizer rule
   * @return {{mark: {class: string}}}
   */
  static get sanitize () {
    return {
      div: {
        class: TemplateMarker.CSS,
        'data-template': true,
        b: true
      }
    }
  }

  renderActions () {
    this.menu = document.createElement('div')
    this.menu.classList.add('w-full', 'bg-white', 'shadow-lg')
    return this.menu
  }

  showActions (mark) {
    this.menu.innerHTML = ''
    TemplateMarker.Options.forEach(opt => {
      const item = document.createElement('div')
      item.classList.add('cdx-option')
      item.innerHTML = opt.text
      item.onclick = () => {
        mark.dataset.template = opt.value
        item.parentElement.children.forEach(sib => sib.classList.remove('active'))
        item.classList.add('active')
        this.renderTag(mark, opt.value)
      }
      if (mark.dataset.template === opt.value) {
        item.classList.add('active')
      }
      this.menu.appendChild(item)
    })
    this.menu.hidden = false
  }

  renderTag (mark, value) {
    if (value === 'HOMETEAM') {
      mark.innerHTML = this.team.name
    } else if (value === 'AWAYTEAM') {
      mark.innerHTML = this.awayTeam.name
    } else if (value === 'GAMEPLACE') {
      mark.innerHTML = this.game.place
    } else if (value === 'GAMETIME') {
      mark.innerHTML = `${this.game.date}, ${this.game.time}`
    } else if (value === 'LEAGUE') {
      mark.innerHTML = this.leagueById(this.team.league_id).name
    }
    this.api.selection.expandToTag(mark)
  }

  hideActions () {
    this.menu.hidden = true
    this.menu.innerHTML = ''
  }
}

export default TemplateMarker
