<template>
  <div>
    <div class="relative">
      <div ref="dropdown" @click="isOpen = !isOpen" class="bg-white dark:bg-gray-700 cursor-pointer flex items-center h-10 rounded-full px-1 hover:bg-gray-200 py-3 dark:text-white dark:bg-gray-800 dark:hover:bg-gray-700">
        <div class="w-8 h-8 rounded-full bg-white overflow-hidden border">
          <img v-if="logo" class="object-contain" :src="logo" alt="Logo">
        </div>
        <div class="hidden md:block text-sm font-bold ml-2 mr-1">{{club ? club.name : ''}}</div>
      </div>
      <div v-if="isOpen" class="absolute right-0 mt-3 w-48 bg-white dark:bg-gray-900 rounded-lg shadow-xl">
        <router-link to="/account" class="block px-4 py-2 text-gray-800 dark:text-white hover:bg-gray-500 hover:text-white dark:hover:bg-gray-700 rounded-t-lg ">{{$t('account')}}</router-link>
        <a href="#" @click="logoutUser()" class="block px-4 py-2 text-gray-800 dark:text-white hover:bg-gray-500 hover:text-white dark:hover:bg-gray-700 rounded-b-lg">{{$t('signout')}}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  data () {
    return {
      isOpen: false
    }
  },
  computed: {
    ...mapGetters({ logo: 'logo', club: 'swissunihockey/club' })
  },
  methods: {
    ...mapActions(['logoutUser']),
    close (e) {
      if (!this.$refs.dropdown.contains(e.target)) {
        this.isOpen = false
      }
    },
    handleEscape (e) {
      if (e.key === 'Esc' || e.key === 'Escape') {
        this.isOpen = false
      }
    }
  },
  created () {
    document.addEventListener('click', this.close)
    document.addEventListener('keydown', this.handleEscape)
  },
  beforeDestroy () {
    document.removeEventListener('click', this.close)
    document.removeEventListener('keydown', this.handleEscape)
  }
}
</script>
