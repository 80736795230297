import ApiClient from '@/assets/js/ApiClient.js'
import dayjs from 'dayjs'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    booklets: [],
    booklet: null,
    club: null,
    clubs: [],
    game: null,
    games: [],
    season: 2023,
    team: null,
    awayTeam: null,
    teams: [],
    leagues: []
  },
  getters: {
    booklet: state => state.booklet,
    booklets: state => state.booklets,
    bookletByGameId: state => gameId => state.booklets.find(b => b.su_game_id === gameId),
    bookletsOwn: (state, getters, rootState) => state.booklets.filter(b => b.club_id === rootState.user.club_id),
    bookletsPublished: (state, getters, rootState) => state.booklets.filter(b => b.created_by === rootState.user.id && b.published),
    bookletsPublishedAll: (state, getters) => state.booklets.filter(b => b.published),
    club: state => state.club,
    clubs: state => state.clubs,
    clubById: state => id => state.clubs.find(c => c.id === id),
    game: state => state.game,
    games: state => state.games,
    gameById: state => id => state.games.find(g => g.id === id),
    nextHomeGames: state => state.games ? state.games.filter(m => (m.teamHome === state.team.name) && dayjs(m.date, 'DD.MM.YYYY').isSameOrAfter(dayjs(), 'day')) : [],
    season: state => state.season,
    team: state => state.team,
    awayTeam: state => state.awayTeam,
    teams: state => state.teams,
    teamById: state => id => state.teams.find(t => t.id === id),
    leagueById: state => id => state.leagues.find(l => l.id === id)
  },
  mutations: {
    addBooklet (state, booklet) {
      state.booklets.push(booklet)
    },
    updateBooklet (state, booklet) {
      const index = state.booklets.findIndex(b => b.id === booklet.id)
      Vue.set(state.booklets, index, booklet)
    },
    setBooklet (state, booklet) {
      state.booklet = booklet
    },
    setBooklets (state, booklets) {
      state.booklets = booklets
    },
    setClub (state, club) {
      state.club = club
    },
    setClubs (state, clubs) {
      state.clubs = clubs
    },
    setGame (state, game) {
      state.game = game
    },
    setGames (state, games) {
      state.games = games
    },
    setTeam (state, team) {
      state.team = team
    },
    setTeams (state, teams) {
      state.teams = teams
    },
    setLeagues (state, leagues) {
      state.leagues = leagues
    },
    setAwayTeam (state, awayTeam) {
      state.awayTeam = awayTeam
    },
    setPlayers (state, players) {
      state.team.players = players
    },
    setStaff (state, staff) {
      state.team.staff = staff
    }
  },
  actions: {
    resetClub ({ commit, dispatch }) {
      commit('setClub', null)
      commit('setTeams', null)
      dispatch('resetGame')
      dispatch('resetTeam')
    },
    resetGame ({ commit, dispatch }) {
      commit('setGame', null)
    },
    resetTeam ({ commit, dispatch }) {
      commit('setTeam', null)
      commit('setGames', null)
      dispatch('resetGame')
    },
    fetchBooklets ({ commit, state }) {
      return new Promise(resolve => {
        ApiClient.get('/api/su/booklets').then(response => {
          commit('setBooklets', response.data)
          resolve()
        })
      })
    },
    fetchClubs ({ commit, state }) {
      return new Promise(resolve => {
        ApiClient.get('/api/su/clubs').then(response => {
          commit('setClubs', response.data)
          resolve()
        })
      })
    },
    fetchGames ({ commit, dispatch, state, getters, rootGetters }) {
      return new Promise(resolve => {
        ApiClient.get(`/api/swissunihockey/games?mode=team&team_id=${state.team.su_team_id}&season=${state.season}&games_per_page=100`).then(response => {
          var customParseFormat = require('dayjs/plugin/customParseFormat')
          dayjs.extend(customParseFormat)
          var isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
          dayjs.extend(isSameOrAfter)
          var games = response.data.data.regions[0].rows
          games = games.reduce((acc, value) => {
            acc.push({ id: value.link.ids[0], date: value.cells[0].text[0], time: value.cells[0].text[1], place: value.cells[1].text[0], teamHome: value.cells[2].text[0], teamAway: value.cells[3].text[0], result: value.cells[4].text[0].toString() })
            return acc
          }, [])
          games.map(match => {
            if (match.date === 'heute') {
              match.date = dayjs().format('DD.MM.YYYY')
            }
          })
          commit('setGames', games)
          resolve()
        })
      })
    },
    fetchLeagues ({ commit, state }) {
      return new Promise(resolve => {
        ApiClient.get('/api/su/leagues').then(response => {
          commit('setLeagues', response.data)
          resolve()
        })
      })
    },
    fetchTeams ({ commit, dispatch, state }) {
      return new Promise(resolve => {
        ApiClient.get(`/api/su/team?club_id=${state.club.id}`).then(response => {
          commit('setTeams', response.data)
          dispatch('updateTeam', response.data[0])
          resolve()
        })
      })
    },
    updateClub ({ commit }, club) {
      commit('setClub', club)
    },
    async updateClubById ({ commit, dispatch, state }, clubId) {
      const club = state.clubs.find(c => c.id === clubId)
      commit('setClub', club)
      await dispatch('fetchTeams')
    },
    updateGame ({ commit, dispatch, state, getters }, game) {
      commit('setGame', game)
      // fetch away team
      ApiClient.get(`/api/su/team?league_id=${state.team.league_id}&name=${game.teamAway}`).then(response => {
        var team = response.data
        team.players = JSON.parse(team.players)
        team.staff = JSON.parse(team.staff)
        commit('setAwayTeam', team)
      })
      const booklet = getters.bookletByGameId(game.id)
      commit('setBooklet', booklet)
    },
    async updateTeam ({ commit, dispatch }, team) {
      commit('setTeam', team)
      if (!Array.isArray(team.players)) {
        commit('setPlayers', JSON.parse(team.players))
        commit('setStaff', JSON.parse(team.staff))
      }
      await dispatch('fetchGames')
    },
    updateDetails ({ commit, dispatch, state }, payload) {
      return new Promise(resolve => {
        ApiClient.put(`/api/su/team/${state.team.id}`, { players: payload.players, staff: payload.staff }).then(response => {
          dispatch('updateTeam', response.data)
          resolve()
        })
      })
    },
    saveBooklet ({ commit, dispatch, state, getters, rootState }, payload) {
      return new Promise(resolve => {
        const booklet = state.booklets.find(b => b.su_game_id === state.game.id)
        if (booklet === undefined) {
          const data = {
            game_id: state.game.id,
            blocks: payload,
            font: rootState.font,
            home_team: state.game.teamHome,
            away_team: state.game.teamAway,
            team_id: state.team.id,
            league: getters.leagueById(state.team.league_id).name,
            game_datetime: dayjs(`${state.game.date} ${state.game.time}`, 'DD.MM.YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss')
          }
          ApiClient.post('/api/su/booklets', data).then(response => {
            commit('addBooklet', response.data)
            resolve()
          })
        } else {
          ApiClient.put(`/api/su/booklets/${booklet.id}`, { game_id: state.game.id, blocks: payload, font: rootState.font }).then(response => {
            commit('updateBooklet', response.data)
            resolve()
          })
        }
      })
    },
    publishBooklet ({ commit, dispatch, state }, payload) {
      return new Promise(resolve => {
        var data = new FormData()
        data.append('name', 'pdf')
        data.append('file', payload.pdf)

        const config = {
          header: {
            'Content-Type': 'multipart/form-data'
          }
        }
        ApiClient.post(`/api/su/booklets/${payload.booklet.id}/publish`, data, config).then(response => {
          commit('updateBooklet', response.data)
          resolve()
        })
      })
    },
    getSwPlayers ({ commit }, team) {
      return new Promise(resolve => {
        ApiClient.get(`/api/swissunihockey/teams/${team.su_team_id}/players`).then(response => {
          const players = response.data.data.regions[0].rows.reduce((acc, value) => {
            acc.push({ position: value.cells[1].text[0], nr: value.cells[0].text[0], name: value.cells[2].text[0], year: value.cells[3].text[0] })
            return acc
          }, [])
          resolve(players)
        })
      })
    }
  }
}
