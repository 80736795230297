<style>
.previewImage {
  height: 210mm;
  width: 148mm;
}
</style>

<template>
  <div class="flex flex-col w-full items-center px-12">
    <div class="mb-2 flex-none" v-for="(img, index) in pages" :key="index">
      <img class="previewImage object-scale-down" :src="img"/>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pages: Array
  }
}
</script>
