<template>
  <div id="app">
    <nav class="fixed w-full flex items-center justify-between bg-white dark:bg-gray-900 border-b border-gray-400 shadow dark:border-gray-800 px-4 z-40 h-16">
      <div class="flex items-center md:w-3/12 text-gray-800 dark:text-white">
        <router-link to="/">
        <svg class="w-10 h-10" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fill-rule="evenodd">
            <circle fill="#38b2ac" cx="12" cy="12" r="12"/>
            <path fill="#FFF" fill-rule="nonzero" d="M17.57895 17.61404H13.6842l1.61404-7.57895-4.1579 7.57895H8.7544L7.9649 9.77193l-1.6842 7.8421H4.70174L7.07018 6.47369h4.47368l.54386 6.2807 3.36842-6.2807h4.50877z"/>
          </g>
        </svg>
      </router-link>
      <div v-if="!isGameDay && !isBookletView" ref="menu" @click="showMenu = !showMenu" class="flex md:hidden flex-shrink-0 rounded-full bg-gray-300 hover:bg-gray-200 dark:bg-gray-700 dark:hover:bg-gray-600 w-10 h-10 items-center justify-center ml-2 cursor-pointer">
          <svg class="fill-current h-5 " :class="[showMenu ? 'text-teal-500' : 'text-black dark:text-white']" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M24 6h-24v-4h24v4zm0 4h-24v4h24v-4zm0 8h-24v4h24v-4z"/></svg>
      </div>
      </div>
      <div class="flex dark:text-white">
        <router-link class="nav-item flex" to="/">
        <svg class="w-4 sm:w-8 h-16 fill-current mx-auto" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12S0 18.623 0 12 5.377 0 12 0zm0 2c5.519 0 10 4.481 10 10s-4.481 10-10 10S2 17.519 2 12 6.481 2 12 2zm.11 8c1.165 0 2.11 1.042 2.11 2.325 0 1.284-.945 2.326-2.11 2.326-1.164 0-2.11-1.042-2.11-2.326 0-1.283.946-2.325 2.11-2.325zm-6.55286 4.9267c.5212-.64363 1.81453-.46076 2.88642.40724 1.07273.86868 1.5205 2.09573.9993 2.73936-.52075.64308-1.81453.46076-2.88726-.40792-1.0719-.868-1.51922-2.0956-.99846-2.73868zm5.56522 4.34584c-.25593-.78766.60184-1.77274 1.9136-2.19896 1.3128-.42655 2.58575-.13379 2.84168.65388.2557.78699-.60184 1.77274-1.91463 2.1993-1.31176.42621-2.58494.13277-2.84065-.65422zm7.59553-7.26303c.82505.07219 1.39666 1.24666 1.27645 2.62069-.1203 1.3751-.88718 2.43247-1.71223 2.36029-.82434-.07212-1.39666-1.24666-1.27636-2.62176.12022-1.37402.8878-2.43134 1.71214-2.35922zM5.5 7C6.3282 7 7 8.042 7 9.325c0 1.284-.6718 2.326-1.5 2.326-.82749 0-1.5-1.042-1.5-2.326C4 8.042 4.67251 7 5.5 7zm13.391.435c.607 1.071.575 2.238-.072 2.605-.647.366-1.665-.206-2.272-1.277-.607-1.071-.575-2.238.072-2.605.647-.366 1.665.206 2.272 1.277zM11.214 4c1.222 0 2.214.704 2.214 1.571s-.992 1.57-2.214 1.57S9 6.438 9 5.571C9 4.704 9.992 4 11.214 4z" fill-rule="evenodd"/>
        </svg>
        </router-link>
        <router-link class="nav-item flex" to="/booklets">
        <svg class="w-4 sm:w-8 h-16 fill-current mx-auto"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 4.706c-2.938-1.83-7.416-2.566-12-2.706v17.714c3.937.12 7.795.681 10.667 1.995.846.388 1.817.388 2.667 0 2.872-1.314 6.729-1.875 10.666-1.995v-17.714c-4.584.14-9.062.876-12 2.706zm-10 13.104v-13.704c5.157.389 7.527 1.463 9 2.334v13.168c-1.525-.546-4.716-1.504-9-1.798zm20 0c-4.283.293-7.475 1.252-9 1.799v-13.171c1.453-.861 3.83-1.942 9-2.332v13.704zm-2-10.214c-2.086.312-4.451 1.023-6 1.672v-1.064c1.668-.622 3.881-1.315 6-1.626v1.018zm0 3.055c-2.119.311-4.332 1.004-6 1.626v1.064c1.549-.649 3.914-1.361 6-1.673v-1.017zm0-2.031c-2.119.311-4.332 1.004-6 1.626v1.064c1.549-.649 3.914-1.361 6-1.673v-1.017zm0 6.093c-2.119.311-4.332 1.004-6 1.626v1.064c1.549-.649 3.914-1.361 6-1.673v-1.017zm0-2.031c-2.119.311-4.332 1.004-6 1.626v1.064c1.549-.649 3.914-1.361 6-1.673v-1.017zm-16-6.104c2.119.311 4.332 1.004 6 1.626v1.064c-1.549-.649-3.914-1.361-6-1.672v-1.018zm0 5.09c2.086.312 4.451 1.023 6 1.673v-1.064c-1.668-.622-3.881-1.315-6-1.626v1.017zm0-2.031c2.086.312 4.451 1.023 6 1.673v-1.064c-1.668-.622-3.881-1.316-6-1.626v1.017zm0 6.093c2.086.312 4.451 1.023 6 1.673v-1.064c-1.668-.622-3.881-1.315-6-1.626v1.017zm0-2.031c2.086.312 4.451 1.023 6 1.673v-1.064c-1.668-.622-3.881-1.315-6-1.626v1.017z"/></svg>
        </router-link>
        <router-link v-if="isLoggedIn" class="nav-item flex" to="/settings">
          <svg class="w-6 sm:w-10 h-16 fill-current mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17.997 18h-11.995l-.002-.623c0-1.259.1-1.986 1.588-2.33 1.684-.389 3.344-.736 2.545-2.209-2.366-4.363-.674-6.838 1.866-6.838 2.491 0 4.226 2.383 1.866 6.839-.775 1.464.826 1.812 2.545 2.209 1.49.344 1.589 1.072 1.589 2.333l-.002.619zm4.811-2.214c-1.29-.298-2.49-.559-1.909-1.657 1.769-3.342.469-5.129-1.4-5.129-1.265 0-2.248.817-2.248 2.324 0 3.903 2.268 1.77 2.246 6.676h4.501l.002-.463c0-.946-.074-1.493-1.192-1.751zm-22.806 2.214h4.501c-.021-4.906 2.246-2.772 2.246-6.676 0-1.507-.983-2.324-2.248-2.324-1.869 0-3.169 1.787-1.399 5.129.581 1.099-.619 1.359-1.909 1.657-1.119.258-1.193.805-1.193 1.751l.002.463z"/></svg>
        </router-link>
      </div>
      <div v-if="!isGameDay" class="flex md:w-3/12 flex-row items-center justify-end">
        <template v-if="!isLoggedIn">
        <div>
          <router-link to="/signup" class="nav-link mr-2" @click="showSignUp = true">{{$t('signup')}}</router-link>
        </div>
        <div>
         <router-link to="/login" class="nav-link" @click="showLogin = true">{{$t('login')}}</router-link>
        </div>
        </template>
        <template v-else>
        <AccountDropdown/>
        </template>
      </div>
    </nav>
    <router-view ref="routerView" :showMenu="showMenu" class="pt-20"></router-view>
    <div v-if="isLoading" class="w-full h-screen fixed bg-white dark:bg-black opacity-75 z-50 top-0 flex items-center justify-center">
      <svg class="animate-spin -ml-1 mr-3 h-12 w-12 text-teal-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    </div>
    <FeedbackBox class="z-50 fixed"></FeedbackBox>
  </div>
</template>

<script>
import AccountDropdown from '@/components/AccountDropdown'
import { mapGetters } from 'vuex'
import FeedbackBox from '@/components/FeedbackBox.vue'

export default {
  name: 'app',
  components: {
    AccountDropdown,
    FeedbackBox
  },
  data () {
    return {
      showMenu: false
    }
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'user', 'isLoading']),
    bookletsVisible () {
      return this.$route.path === '/'
    },
    isGameDay () {
      return this.$route.name === 'Gameday'
    },
    isBookletView () {
      return this.$route.name === 'Booklets'
    }
  },
  methods: {
    close (e) {
      if (this.$refs.routerView.$refs.menu) {
        if (!this.$refs.routerView.$refs.menu.contains(e.target) && !this.$refs.menu.contains(e.target)) {
          this.showMenu = false
        }
      }
    },
    handleEscape (e) {
      if (e.key === 'Esc' || e.key === 'Escape') {
        this.showMenu = false
      }
    }
  },
  created () {
    document.addEventListener('click', this.close)
    document.addEventListener('keydown', this.handleEscape)
  },
  beforeDestroy () {
    document.removeEventListener('click', this.close)
    document.removeEventListener('keydown', this.handleEscape)
  },
  async mounted () {
    this.$store.dispatch('startLoading')
    await this.$store.dispatch('swissunihockey/fetchBooklets')
    await this.$store.dispatch('swissunihockey/fetchClubs')
    await this.$store.dispatch('swissunihockey/fetchLeagues')
    this.$store.dispatch('endLoading')
  }
}
</script>
