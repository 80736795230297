import store from '@/store'
import ApiClient from '@/assets/js/ApiClient.js'
import { i18n } from '@/main.js'

class Topscorer {
  constructor ({ api, data }) {
    this.api = api
    this.data = data
    this.clubId = this.data.clubId || store.getters['swissunihockey/team'].su_club_id
    this.awayClubId = this.data.awayClubId || store.getters['swissunihockey/awayTeam'].su_club_id
    this.title = this.data.title || i18n.tc('editorjs.topscorer.title_default')
  }

  static get toolbox () {
    return {
      title: i18n.tc('editorjs.topscorer.title'),
      icon: '<svg class="h-10 w-10 p-1 fill-current" xmlns="http://www.w3.org/2000/svg"><text fill="#666" fill-rule="evenodd" font-family="Helvetica" font-size="17"><tspan x="1" y="20">VS</tspan></text></svg>'
    }
  }

  render () {
    this.wrapper = document.createElement('div')
    this.tsWrapper = document.createElement('div')
    this.tsWrapper.classList.add('flex', 'w-full', 'gap-2')

    var title = document.createElement('h6')
    title.innerHTML = this.title
    title.addEventListener('input', (e) => {
      this.title = e.target.innerHTML
    })
    this.wrapper.appendChild(title)
    this.addTopscorer(this.clubId)
    this.addTopscorer(this.awayClubId)
    this.wrapper.appendChild(this.tsWrapper)

    return this.wrapper
  }

  save (blockContent) {
    return {
      title: this.title
    }
  }

  async addTopscorer (clubId) {
    ApiClient.get(`/api/swissunihockey/topscorer/${clubId}`).then(response => {
      const data = response.data.data.regions[0].rows[0].cells
      const image = data[0].image.url
      const name = data[1].text
      const team = data[2].text
      const points = data[3].text
      const topscorerDiv = document.createElement('div')
      topscorerDiv.classList.add('flex', 'flex-col', 'bg-no-repeat', 'bg-contain', 'h-96', 'w-1/2', 'item-center', 'justify-center')
      topscorerDiv.style.backgroundImage = "url('img/bg_mobitop_de.png')"

      const imageElement = document.createElement('div')
      imageElement.classList.add('mx-auto', 'w-28', 'h-44', 'bg-contain', 'bg-no-repeat', 'bg-center')
      imageElement.style.backgroundImage = "url('" + image + "')"

      const nameElement = document.createElement('span')
      nameElement.classList.add('text-white', 'font-bold', 'text-center')
      nameElement.innerText = name

      const teamElement = document.createElement('span')
      teamElement.classList.add('text-white', 'text-xs', 'text-center')
      teamElement.innerText = team

      const pointsElement = document.createElement('span')
      pointsElement.classList.add('text-white', 'text-center', 'font-bold')
      pointsElement.innerText = points

      topscorerDiv.appendChild(imageElement)
      topscorerDiv.appendChild(nameElement)
      topscorerDiv.appendChild(teamElement)
      topscorerDiv.appendChild(pointsElement)

      this.tsWrapper.appendChild(topscorerDiv)
    })
  }
}

export default Topscorer
