// src/lang/index.js

import de from './translations/de'
import fr from './translations/fr'
import it from './translations/it'

export default {
  de,
  fr,
  it
}
