import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: () => import('../views/SignUp.vue')
  },
  {
    path: '/booklets',
    name: 'Booklets',
    component: () => import('../views/Booklets.vue')
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('../views/Settings.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import('../views/Account.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import('../views/Terms.vue')
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import('../views/Privacy.vue')
  },
  {
    path: '/gameday',
    name: 'Gameday',
    component: () => import('../views/Gameday.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkExactActiveClass: 'is-active',
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

function timeout (ms) {
  return new Promise(resolve => setTimeout(() => { resolve() }, ms))
}
router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.isLoggedIn && to.path !== '/') {
      next({
        path: '/',
        params: { nextUrl: to.fullPath }
      })
      return
    }
    // initalize store values
    if (!store.state.initialized) {
      if (to.query.stripe) {
        store.dispatch('startLoading')
        await timeout(3000)
        await store.dispatch('initialize')
        store.dispatch('endLoading')
        next({ path: to.path, query: {} })
        return
      } else {
        await store.dispatch('initialize')
      }
    }
  } else {
    if (store.state.accessToken !== null && !store.state.initialized) {
      if (to.query.stripe) {
        store.dispatch('startLoading')
        await timeout(3000)
        await store.dispatch('initialize')
        store.dispatch('endLoading')
        next({ path: to.path, query: {} })
        return
      } else {
        await store.dispatch('initialize')
      }
    }
  }
  if (store.getters.isLoggedIn && !store.getters.hasValidSubscription && to.path !== '/account') {
    next({
      path: '/account'
    })
    return
  }
  next()
})

export default router
