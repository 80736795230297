import store from '@/store'
import dayjs from 'dayjs'
import { i18n } from '@/main.js'

class GamesTable {
  constructor ({ api, data }) {
    this.api = api
    this.data = data
    this.team = this.data.team || store.getters['swissunihockey/team']
    this.games = this.data.games || store.getters['swissunihockey/games']
    this.game = this.data.game || store.getters['swissunihockey/game']
    this.title = this.data.title || 'Nächste Spiele'
    this.gamesCount = this.data.gamesCount || 5
    this.pastGames = this.data.pastGames || false
  }

  static get toolbox () {
    return {
      title: i18n.tc('editorjs.games_table.title'),
      icon: '<svg class="h-12 w-12 p-1 fill-current" xmlns="http://www.w3.org/2000/svg"><g fill="#000" fill-rule="evenodd" font-family="Helvetica" font-size="8" font-weight="bold"><text><tspan x="5" y="20">_ : _</tspan></text><text><tspan x="5" y="10">_ : _</tspan></text></g></svg>'
    }
  }

  t (key) {
    return i18n.tc(`editorjs.games_table.${key}`)
  }

  render () {
    this.wrapper = document.createElement('div')
    var title = document.createElement('h6')
    title.innerHTML = this.title
    title.addEventListener('input', (e) => {
      this.title = e.target.innerHTML
    })
    this.wrapper.appendChild(title)
    var row, cell
    var table = document.createElement('table')
    table.classList.add('swissunihockey-table', 'swissunihockey-games-table')
    var thead = table.createTHead()
    row = thead.insertRow(-1)
    var tbody = table.createTBody()
    var filterGames = this.games

    if (!this.pastGames) {
      row.innerHTML = `<th>${this.t('when')}</th><th>${this.t('place')}</th><th>${this.t('home')}</th><th>${this.t('guest')}</th>`
      filterGames = this.games.filter(m => dayjs(m.date, 'DD.MM.YYYY').isAfter(dayjs(this.game.date, 'DD.MM.YYYY')))
    } else {
      row.innerHTML = `<th>${this.t('when')}</th><th>${this.t('place')}</th><th>${this.t('home')}</th><th>${this.t('guest')}</th><th>${this.t('result')}</th>`
    }
    if (this.gamesCount > 0) {
      filterGames = filterGames.splice(0, this.gamesCount)
    }
    filterGames.forEach(match => {
      row = tbody.insertRow(-1)
      this._addCell(row, match.datetime || `${match.date} ${match.time} Uhr`, 'datetime', match.id)
      this._addCell(row, match.place, 'place', match.id)
      this._addCell(row, match.teamHome)
      this._addCell(row, match.teamAway)
      if (this.pastGames) {
        cell = this._addCell(row, match.result)
        if (match.result !== '') {
          var result = match.result.split(':').map(Number)
          var isHome = (this.team.name === match.teamHome)
          const cellClass = ((isHome && (result[0] > result[1])) || (!isHome && (result[0] < result[1]))) ? 'bg-green-200' : 'bg-red-200'
          cell.classList.add(cellClass)
        }
      }
    })
    this.wrapper.appendChild(table)
    return this.wrapper
  }

  save (blockContent) {
    console.log(this.games)
    return {
      title: this.title,
      games: this.games,
      gamesCount: this.gamesCount,
      pastGames: this.pastGames,
      team: this.team
    }
  }

  _addCell (row, value, key, id) {
    var cell = row.insertCell(-1)
    cell.innerHTML = value
    if (key) {
      cell.contentEditable = 'true'
      cell.addEventListener('input', (e) => {
        const index = this.games.findIndex(g => g.id === id)
        this.games[index][key] = e.target.innerHTML
      })
    }

    return cell
  }
}

export default GamesTable
