<template>
<div>
 <h4 class="text-2xl dark:text-white mt-0">{{$t('booklets.title')}}</h4>
 <p v-if="!getBooklets.length" class="text-gray-500 mt-2 text-sm">{{$t('booklets.no_data')}}</p>
<div v-for="book in getBooklets" :key="book.id">
  <div :class="[booklet && book.id === booklet.id ? 'bg-gray-400 dark:bg-gray-600' : 'bg-gray-300 dark:bg-gray-700']" class="flex flex-col dark:text-white p-3 rounded my-2 pt-1 pb-2 text-sm">
    <div class="flex justify-between">
      <div class="flex flex-col w-full">
        <div class="flex justify-between w-full mb-2 items-stretch">
          <div class="font-semibold">{{book.league}}<br>{{formatDate(book.game_datetime)}}</div>
          <BookletMore :booklet="book" @onLoadBooklet="loadBooklet"></BookletMore>
        </div>
        <div>{{book.home_team}}</div>
        <div>{{book.away_team}}</div>
      </div>
    </div>
    <div class="flex items-center justify-between mt-1">
      <div class="text-xs mt-1 text-gray-500 dark:text-gray-600">{{updatedAt(book)}}</div>
      <div class="flex">
        <div ref="warning">
          <svg v-if="isModified(book) && isLoggedIn" class="mr-1 fill-current h-4 w-4 text-yellow-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.351 6.493c-.08-.801.55-1.493 1.351-1.493s1.431.692 1.351 1.493l-.801 8.01c-.029.282-.266.497-.55.497s-.521-.215-.55-.498l-.801-8.009zm1.351 12.757c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25z"/></svg>
        </div>
        <div ref="published">
          <svg v-if="book.published && isLoggedIn" class="fill-current h-4 w-4 text-teal-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.334 11.96c-.713-.726-.872-1.829-.393-2.727.342-.64.366-1.401.064-2.062-.301-.66-.893-1.142-1.601-1.302-.991-.225-1.722-1.067-1.803-2.081-.059-.723-.451-1.378-1.062-1.77-.609-.393-1.367-.478-2.05-.229-.956.347-2.026.032-2.642-.776-.44-.576-1.124-.915-1.85-.915-.725 0-1.409.339-1.849.915-.613.809-1.683 1.124-2.639.777-.682-.248-1.44-.163-2.05.229-.61.392-1.003 1.047-1.061 1.77-.082 1.014-.812 1.857-1.803 2.081-.708.16-1.3.642-1.601 1.302s-.277 1.422.065 2.061c.479.897.32 2.001-.392 2.727-.509.517-.747 1.242-.644 1.96s.536 1.347 1.17 1.7c.888.495 1.352 1.51 1.144 2.505-.147.71.044 1.448.519 1.996.476.549 1.18.844 1.902.798 1.016-.063 1.953.54 2.317 1.489.259.678.82 1.195 1.517 1.399.695.204 1.447.072 2.031-.357.819-.603 1.936-.603 2.754 0 .584.43 1.336.562 2.031.357.697-.204 1.258-.722 1.518-1.399.363-.949 1.301-1.553 2.316-1.489.724.046 1.427-.249 1.902-.798.475-.548.667-1.286.519-1.996-.207-.995.256-2.01 1.145-2.505.633-.354 1.065-.982 1.169-1.7s-.135-1.443-.643-1.96zm-12.584 5.43l-4.5-4.364 1.857-1.857 2.643 2.506 5.643-5.784 1.857 1.857-7.5 7.642z"/></svg>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
</template>

<script>
import dayjs from 'dayjs'
import dateMixin from '@/mixin/dateMixin.js'
import { mapActions, mapGetters } from 'vuex'
import BookletMore from '@/components/BookletMore'
import Tooltip from 'codex-tooltip'

export default {
  mixins: [dateMixin],
  components: {
    BookletMore
  },
  data () {
    return {
      tooltip: new Tooltip()
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      isLoggedIn: 'isLoggedIn',
      bookletsOwn: 'swissunihockey/bookletsOwn',
      bookletsPublishedAll: 'swissunihockey/bookletsPublishedAll',
      teamById: 'swissunihockey/teamById',
      gameById: 'swissunihockey/gameById',
      booklet: 'swissunihockey/booklet'
    }),
    getBooklets () {
      const format = 'YYYY-MM-DD HH:mm:ss'
      var result
      if (this.isLoggedIn) {
        result = this.bookletsOwn
      } else {
        result = this.bookletsPublishedAll
      }
      return result.sort((a, b) => dayjs(b.game_datetime, format) - dayjs(a.game_datetime, format))
    }
  },
  methods: {
    ...mapActions({
      publishBooklet: 'swissunihockey/publishBooklet'
    }),
    updatedAt (booklet) {
      return dayjs(booklet.blocks[0].time).format('DD.MM.YYYY HH:mm')
    },
    isModified (booklet) {
      return booklet.published && dayjs(booklet.blocks[0].time).isAfter(booklet.updated_at)
    },
    async loadBooklet (booklet) {
      if (this.isLoggedIn) {
        const team = this.teamById(booklet.team_id)
        await this.$store.dispatch('swissunihockey/updateTeam', team)
        this.$store.dispatch('swissunihockey/updateGame', this.gameById(booklet.su_game_id))
        this.$emit('onUpdateMatchheft', booklet.blocks)
      }
    }
  },
  mounted () {
    if (this.$refs.published) {
      this.$refs.published.forEach(pub => {
        this.tooltip.onHover(pub, this.$t('published'), {
          placement: 'top'
        })
      })
    }
    if (this.$refs.warning) {
      this.$refs.warning.forEach(pub => {
        this.tooltip.onHover(pub, this.$t('warning_booklet_modified'), {
          placement: 'top'
        })
      })
    }
  }
}

</script>
