import { create } from '../documentUtils'
import { i18n } from '@/main.js'

const CSS = {
  hidden: 'invisible',
  toolBar: 'players-toolbar',
  settings: 'ce-settings',
  settingsButton: 'ce-settings__button',
  settingsButtonDelete: 'ce-settings__button--delete',
  settingsButtonConfirm: 'ce-settings__button--confirm',
  settingsOpened: 'ce-settings--opened',
  zonePlugin: 'ce-settings__plugin-zone',
  zoneDefault: 'ce-settings__default-zone'
}

export class ToolBar {
  constructor (api, emptyObject) {
    this.api = api
    this.emptyObject = emptyObject
    this.index = -1
    this.objects = null
    this.deleteBtn = this._deleteButton()
    var actionBtn = create('div')
    actionBtn.innerHTML = '<span class="ce-toolbar__settings-btn"><svg class="icon icon--dots" width="8px" height="8px"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#dots"></use></svg></span>'
    const zone1 = create('div', [CSS.zonePlugin], null, [this._addButton(), this._addTopScorer()])
    const zone2 = create('div', [CSS.zoneDefault], null, [this._moveUpButton(), this.deleteBtn, this._moveDownButton()])
    this.settings = create('div', [CSS.settings], null, [zone1, zone2])
    actionBtn.addEventListener('click', (e) => {
      this.settings.classList.toggle(CSS.settingsOpened)
    })
    this.toolbar = create('div', [CSS.toolBar, CSS.hidden], null, [actionBtn, this.settings])
    this.api.tooltip.onHover(this.toolbar, i18n.tc('editor.edit'), {
      placement: 'top'
    })
  }

  hide () {
    this.toolbar.classList.add(CSS.hidden)
    this.deleteBtn.classList.remove(CSS.settingsButtonConfirm)
  }

  show (coord, alignRight, objects, index) {
    this.deleteBtn.classList.remove(CSS.settingsButtonConfirm)
    this.objects = objects
    this.index = index
    this.toolbar.classList.remove(CSS.hidden)
    const ypos = coord.y1
    this.toolbar.style.top = ypos - 4 + 'px'
    this.settings.classList.remove('ce-settings--opened')
    if (alignRight) {
      const xpos = coord.x2
      this.toolbar.style.left = xpos + 16 + 'px'
      this.settings.style.right = '0px'
      this.settings.style.left = null
    } else {
      const xpos = coord.x1
      this.toolbar.style.left = xpos - 38 + 'px'
      this.settings.style.left = '0px'
      this.settings.style.right = null
    }
  }

  open () {
    this.settings.classList.add(CSS.settingsOpened)
  }

  get htmlElement () {
    return this.toolbar
  }

  _addTopScorer () {
    var btn = create('div', [CSS.settingsButton])
    this.api.tooltip.onHover(btn, i18n.tc('editor.topscorer'))
    btn.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path d="M6 11c0 2.206 1.794 4 4 4 1.761 0 3.242-1.151 3.775-2.734l2.224-1.291.001.025c0 3.314-2.686 6-6 6s-6-2.686-6-6 2.686-6 6-6c1.084 0 2.098.292 2.975.794l-2.21 1.283c-.248-.048-.503-.077-.765-.077-2.206 0-4 1.794-4 4zm4-2c-1.105 0-2 .896-2 2s.895 2 2 2 2-.896 2-2l-.002-.015 3.36-1.95c.976-.565 2.704-.336 3.711.159l4.931-2.863-3.158-1.569.169-3.632-4.945 2.87c-.07 1.121-.734 2.736-1.705 3.301l-3.383 1.964c-.29-.163-.621-.265-.978-.265zm9.957 2.858c-.301-.483-1.066-1.061-1.962-.947l.005.089c0 4.411-3.589 8-8 8s-8-3.589-8-8 3.589-8 8-8c1.475 0 2.853.408 4.041 1.107.334-.586.428-1.544.146-2.18-1.275-.589-2.69-.927-4.187-.927-5.523 0-10 4.477-10 10 0 2.958 1.291 5.607 3.332 7.438l-1.307 4.052c-.123.383.35.679.64.399l3.002-2.888c1.312.634 2.778.999 4.333.999 1.556 0 3.023-.362 4.335-.996l3 2.886c.29.28.763-.017.64-.399l-1.306-4.05c1.839-1.652 3.065-3.973 3.288-6.583z"/></svg>'
    btn.addEventListener('click', (event) => {
      this.objects.forEach(obj => { obj.topscorer = false })
      this.objects[this.index].topscorer = true
      const e = new CustomEvent('toolbarBtnClicked', { detail: { index: this.index, open: false } })
      this.toolbar.dispatchEvent(e)
    })
    return btn
  }

  _addButton () {
    var btn = create('div', [CSS.settingsButton])
    this.api.tooltip.onHover(btn, i18n.tc('editor.add'))
    btn.innerHTML = '<svg width="14px" height="14px"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#plus"></use></svg>'
    btn.addEventListener('click', (event) => {
      this.objects.splice(this.index + 1, 0, this.emptyObject)
      const e = new CustomEvent('toolbarBtnClicked', { detail: { index: this.index + 1, open: false } })
      this.toolbar.dispatchEvent(e)
    })
    return btn
  }

  _moveUpButton () {
    var btn = create('div', [CSS.settingsButton])
    this.api.tooltip.onHover(btn, i18n.tc('editor.move_up'))
    btn.innerHTML = '<svg width="14px" height="14px"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#arrow-up"></use></svg>'
    btn.addEventListener('click', (event) => {
      if (this.index === 0) {
        var elm = event.target
        var newone = elm.cloneNode(true)
        newone.classList.add('wobble')
        elm.parentNode.replaceChild(newone, elm)
      } else {
        this._move(this.objects, this.index, this.index - 1)
        const e = new CustomEvent('toolbarBtnClicked', { detail: { index: this.index - 1, open: true } })
        this.toolbar.dispatchEvent(e)
      }
    })
    return btn
  }

  _moveDownButton () {
    var btn = create('div', [CSS.settingsButton])
    this.api.tooltip.onHover(btn, i18n.tc('editor.move_down'))
    btn.innerHTML = '<svg width="14px" height="14px"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#arrow-down"></use></svg>'
    btn.addEventListener('click', (event) => {
      if (this.index === this.objects.length - 1) {
        var elm = event.target
        var newone = elm.cloneNode(true)
        newone.classList.add('wobble')
        elm.parentNode.replaceChild(newone, elm)
      } else {
        this._move(this.objects, this.index, this.index + 1)
        const e = new CustomEvent('toolbarBtnClicked', { detail: { index: this.index + 1, open: true } })
        this.toolbar.dispatchEvent(e)
      }
    })
    return btn
  }

  _deleteButton () {
    var btn = create('div', [CSS.settingsButton, CSS.settingsButtonDelete])
    this.api.tooltip.onHover(btn, i18n.tc('editor.delete'))
    btn.innerHTML = '<svg width="12px" height="12px" class="icon icon--cross fill-current"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#cross"></use></svg>'
    btn.addEventListener('click', (event) => {
      if (btn.classList.contains(CSS.settingsButtonConfirm)) {
        this.objects.splice(this.index, 1)
        const e = new CustomEvent('toolbarBtnClicked', { detail: { index: null, open: false } })
        this.toolbar.dispatchEvent(e)
      }
      btn.classList.toggle(CSS.settingsButtonConfirm)
    })
    return btn
  }

  _move (arr, fromIndex, toIndex) {
    var element = arr[fromIndex]
    arr.splice(fromIndex, 1)
    arr.splice(toIndex, 0, element)
  }
}
