<style>
  .dropdown-menu {
    @apply rounded;
  }
  .dropdown ul li a.active {
    @apply bg-gray-400 dark:bg-gray-700;
  }
  .font-item {
    @apply bg-gray-300 hover:bg-gray-400 dark:bg-gray-800 dark:text-white dark:hover:bg-gray-700 py-2 px-4 block whitespace-nowrap cursor-pointer;
  }
</style>
<template>
<div>
  <div v-if="showMenu" class="top-0 left-0 z-50 fixed w-full h-screen" @click="showMenu = false"></div>
  <div class="dropdown inline-block relative w-full" @click="showMenu = !showMenu">
    <span class="dark:text-white font-semibold text-sm">{{title}}</span>
    <div class="bg-gray-300 text-gray-800 dark:bg-gray-700 dark:text-white py-1 px-3 rounded inline-flex items-center justify-between cursor-pointer mb-1 w-full" :class="{'py-2' : xl}">
      <div class="whitespace-nowrap">
      <span><slot v-bind:item="value">{{value}}</slot></span>
      </div>
      <svg class="fill-current h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/> </svg>
    </div>
    <div class="dropdown-menu absolute left-0 z-50 overflow-y-auto min-w-full scrollbar" :class="{ 'hidden': !showMenu }">
      <div v-for="(item, index) in options" :key="index"><a :class="{ 'active': isActive(item) }" class="font-item truncate font-normal text-black dark:text-white hover:text-black dark:hover:text-white" href="#" @click.stop.prevent="itemChanged(item)">
        <slot v-bind:item="item">{{item}}</slot>
      </a></div>
    </div>
  </div>
</div>
</template>

<script>

export default {
  props: ['value', 'options', 'title', 'xl'],
  data () {
    return {
      showMenu: false
    }
  },
  methods: {
    itemChanged (item) {
      this.showMenu = false
      this.$emit('input', item)
      this.$emit('changed')
    },
    isActive (item) {
      return this.value === item
    }
  }
}
</script>
