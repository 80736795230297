import { i18n } from '@/main.js'

const CSS = {
  wrapper: 'image-wrapper',
  image: 'image',
  hidden: 'file-hidden',
  invisible: 'file-invisible',
  thumb: 'thumb',
  fileUpload: 'file-upload',
  imageCouter: 'image-counter',
  imageTitle: 'image-title',
  settingsButtonActive: 'cdx-settings-button--active',
  settingsButton: 'ce-settings__button',
  settingsButtonDelete: 'ce-settings__button--delete',
  settingsButtonConfirm: 'ce-settings__button--confirm',
  noBorder: 'no-border',
  noImages: 'no-image'
}

class ImagesPlugin {
  constructor ({ api, data }) {
    this.api = api
    this.data = data
    this.images = data.images || [{ caption: 'Sponsor 1', imageData: null }, { caption: 'Sponsor 2', imageData: null }, { caption: 'Sponsor 3', imageData: null }]
    this.showTitle = data.showTitle === undefined ? false : data.showTitle
    this.showBorder = data.showBorder === undefined ? true : data.showBorder
    this.imgObjects = []
    this.images.forEach(img => {
      const image = new Image(img.imageData, img.caption, this.showTitle)
      this.imgObjects.push(image)
    })
    this.wrapper = undefined
    this.settings = [
      {
        name: '1',
        tooltip: i18n.tc('editorjs.images.image_1'),
        typ: 'img',
        class: CSS.imageCouter,
        count: 1,
        icon: '<svg class="fill-current" width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><g fill-rule="evenodd" font-family="Helvetica" font-size="20"><text text-anchor="middle" alignment-baseline="central"><tspan x="10" y="20">1</tspan></text></g></svg>'
      },
      {
        name: '2',
        tooltip: i18n.tc('editorjs.images.image_2'),
        typ: 'img',
        class: CSS.imageCouter,
        count: 2,
        icon: '<svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><g fill-rule="evenodd" font-family="Helvetica" font-size="20"><text text-anchor="middle" alignment-baseline="central"><tspan x="10" y="20">2</tspan></text></g></svg>'
      },
      {
        name: '3',
        tooltip: i18n.tc('editorjs.images.image_3'),
        typ: 'img',
        class: CSS.imageCouter,
        count: 3,
        icon: '<svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><g fill-rule="evenodd" font-family="Helvetica" font-size="20"><text text-anchor="middle" alignment-baseline="central"><tspan x="10" y="20">3</tspan></text></g></svg>'
      },
      {
        name: 'title',
        tooltip: i18n.tc('editorjs.images.show_title'),
        class: CSS.imageTitle,
        typ: 'title',
        icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0.2 -0.3 9 11.4" width="12" height="14"> <path d="M0 2.77V.92A1 1 0 01.2.28C.35.1.56 0 .83 0h7.66c.28.01.48.1.63.28.14.17.21.38.21.64v1.85c0 .26-.08.48-.23.66-.15.17-.37.26-.66.26-.28 0-.5-.09-.64-.26a1 1 0 01-.21-.66V1.69H5.6v7.58h.5c.25 0 .45.08.6.23.17.16.25.35.25.6s-.08.45-.24.6a.87.87 0 01-.62.22H3.21a.87.87 0 01-.61-.22.78.78 0 01-.24-.6c0-.25.08-.44.24-.6a.85.85 0 01.61-.23h.5V1.7H1.73v1.08c0 .26-.08.48-.23.66-.15.17-.37.26-.66.26-.28 0-.5-.09-.64-.26A1 1 0 010 2.77z"></path></svg>'
      },
      {
        name: 'border',
        tooltip: i18n.tc('editorjs.images.show_border'),
        class: CSS.imageTitle,
        typ: 'border',
        icon: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path d="M22 2v20h-20v-20h20zm2-2h-24v24h24v-24z"/></svg>'
      }
    ]
  }

  static get toolbox () {
    return {
      title: i18n.tc('editorjs.images.title'),
      icon: '<svg width="17" height="15" viewBox="0 0 336 276" xmlns="http://www.w3.org/2000/svg"><path d="M291 150V79c0-19-15-34-34-34H79c-19 0-34 15-34 34v42l67-44 81 72 56-29 42 30zm0 52l-43-30-56 30-81-67-66 39v23c0 19 15 34 34 34h178c17 0 31-13 34-29zM79 0h178c44 0 79 35 79 79v118c0 44-35 79-79 79H79c-44 0-79-35-79-79V79C0 35 35 0 79 0z"/></svg>'
    }
  }

  renderSettings () {
    const wrapper = document.createElement('div')

    this.settings.forEach(tune => {
      const button = document.createElement('div')

      button.classList.add('cdx-settings-button', tune.class)
      if (tune.typ === 'img') {
        button.classList.toggle('cdx-settings-button--active', (this.images.length === tune.count))
      } else if (tune.typ === 'title') {
        button.classList.toggle('cdx-settings-button--active', this.showTitle)
      } else if (tune.typ === 'border') {
        button.classList.toggle('cdx-settings-button--active', this.showBorder)
      }
      button.innerHTML = tune.icon
      wrapper.appendChild(button)
      this.api.tooltip.onHover(button, tune.tooltip)
      button.addEventListener('click', () => {
        this._toggleSettings(tune)
        if (tune.typ === 'img') {
          wrapper.children.forEach(c => c.classList.remove(CSS.settingsButtonActive))
        }
        button.classList.toggle(CSS.settingsButtonActive)
      })
    })

    return wrapper
  }

  render () {
    this.wrapper = document.createElement('div')
    this._refreshView()
    return this.wrapper
  }

  save (blockContent) {
    var data = []
    this.imgObjects.forEach(img => {
      data.push({ caption: img.caption(), imageData: img.data() })
    })
    return {
      images: data,
      showTitle: this.showTitle,
      showBorder: this.showBorder
    }
  }

  _refreshView () {
    this.wrapper.innerHTML = ''
    this.wrapper.classList.add(CSS.wrapper)

    this.wrapper.classList.toggle(CSS.noBorder, !this.showBorder)

    this.imgObjects.forEach(img => {
      this.wrapper.appendChild(img.htmlElement)
    })
  }

  _toggleSettings (tune) {
    if (tune.typ === 'img') {
      while (tune.count > this.imgObjects.length) {
        this.imgObjects.push(new Image(null, 'Sponsor', this.showTitle))
      }
      while (tune.count < this.imgObjects.length) {
        this.imgObjects.pop()
      }
    }
    if (tune.typ === 'title') {
      this.showTitle = !this.showTitle
      this.imgObjects.forEach(img => img.toggleShowTitle())
    }
    if (tune.typ === 'border') {
      this.showBorder = !this.showBorder
    }
    this._refreshView()
  }
}

class Image {
  constructor (imageData, caption, showTitle) {
    this.wrapper = document.createElement('div')
    var label = document.createElement('label')
    label.innerHTML = '<svg class="w-6 h-6" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" /></svg>'
    var upload = document.createElement('input')
    upload.setAttribute('type', 'file')
    upload.classList.add(CSS.hidden)
    label.appendChild(upload)
    this.uploadWrapper = document.createElement('div')
    this.uploadWrapper.classList.add(CSS.fileUpload)
    this.uploadWrapper.appendChild(label)
    this.imageWrapper = document.createElement('div')
    this.imageWrapper.classList.add(CSS.thumb)
    this.imageElement = document.createElement('img')
    this.imageWrapper.appendChild(this.imageElement)
    // load image
    this.imageData = imageData && imageData.length ? imageData : null
    if (this.imageData) {
      this.imageElement.src = this.imageData
      this.uploadWrapper.classList.add(CSS.hidden)
    } else {
      this.wrapper.classList.add(CSS.noImages)
      this.uploadWrapper.classList.add(CSS.invisible)
    }

    // filereader
    const reader = new FileReader()
    reader.addEventListener('load', () => {
      // convert image file to base64 string
      this.imageData = reader.result
      this.imageElement.src = reader.result
    }, false)

    // event upload file
    upload.addEventListener('change', (event) => {
      reader.readAsDataURL(event.target.files[0])
      this.uploadWrapper.classList.add(CSS.hidden)
      this.wrapper.classList.remove(CSS.noImages)
    })

    // delete image
    const deleteButton = this._deleteButton()
    deleteButton.classList.add(CSS.hidden)
    this.wrapper.addEventListener('mouseenter', (event) => {
      if (this.imageData) {
        deleteButton.classList.remove(CSS.hidden)
      } else {
        this.uploadWrapper.classList.remove(CSS.invisible)
      }
    })

    this.wrapper.addEventListener('mouseleave', (event) => {
      if (this.imageData) {
        deleteButton.classList.add(CSS.hidden)
        deleteButton.classList.remove(CSS.settingsButtonConfirm)
      } else {
        this.uploadWrapper.classList.add(CSS.invisible)
      }
    })

    // title
    this.title = document.createElement('h6')
    if (!showTitle) {
      this.title.classList.add(CSS.hidden)
    }
    this.title.innerHTML = caption
    this.title.contentEditable = 'true'

    this.wrapper.classList.add(CSS.image)
    this.wrapper.appendChild(deleteButton)
    this.wrapper.appendChild(this.title)
    this.wrapper.appendChild(this.uploadWrapper)
    this.wrapper.appendChild(this.imageWrapper)
  }

  get htmlElement () {
    return this.wrapper
  }

  caption () {
    return this.title.innerHTML
  }

  data () {
    return this.imageData
  }

  toggleShowTitle () {
    this.title.classList.toggle(CSS.hidden)
  }

  _deleteButton () {
    var btn = document.createElement('div')
    btn.classList.add(CSS.settingsButton, CSS.settingsButtonDelete)
    btn.innerHTML = '<svg width="12px" height="12px" class="icon icon--cross fill-current"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#cross"></use></svg>'
    btn.addEventListener('click', (event) => {
      if (btn.classList.contains(CSS.settingsButtonConfirm)) {
        this.imageData = null
        this.imageElement.src = ''
        this.uploadWrapper.classList.remove(CSS.hidden)
        this.wrapper.classList.add(CSS.noBorder)
        this.wrapper.classList.add(CSS.noImages)
        btn.classList.add(CSS.hidden)
      }
      btn.classList.toggle(CSS.settingsButtonConfirm)
    })
    return btn
  }
}
export default ImagesPlugin
