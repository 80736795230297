<style>
  .maxheight {
    max-height: 125px;
  }
</style>
<template>
  <div v-if="club">
    <div v-if="!user" class="label flex items-center justify-between">
      <span>{{club.name}}</span>
      <DeleteButton  @onDelete="resetClub()"></DeleteButton>
    </div>
    <v-select class="mb-4" :title="$t('team')" :options="teams" :value="team" v-on:input="teamSelected" :xl="true">
      <template v-slot:default="prop">
        {{prop.item ? leagueById(prop.item.league_id).name : '...'}}
      </template>
    </v-select>
    <template v-if="!isSettingsView && gamesHome.length > 0">
      <span class="text-sm font-semibold dark:text-white">{{$t('next_homegames')}}</span>
      <div class="list-label">
      <div v-for="g in gamesHome.slice(0, 3)" :key="g.id" @click="gameSelected(g)" class="option first:rounded-t last:rounded-b text-sm" :class="{'active' : game && game.id === g.id}">
        <div class="flex justify-between">
          <div>{{g.date}}</div>
        </div>
        <div>
          {{g.teamAway}}
        </div>
      </div>
      </div>
    </template>
  <button v-if="!isSettingsView" class="w-full px-3 mt-5 mb-2" @click="reset()">{{$t('reset')}}</button>
  </div>
</template>

<script>
import dateMixin from '@/mixin/dateMixin.js'
import { mapGetters } from 'vuex'
import DeleteButton from '@/components/DeleteButton.vue'
import VSelect from '@/components/v-select.vue'

export default {
  name: 'swissunihockey',
  mixins: [dateMixin],
  components: {
    DeleteButton,
    'v-select': VSelect
  },
  props: {
    isSettingsView: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      user: 'user',
      club: 'swissunihockey/club',
      clubs: 'swissunihockey/clubs',
      game: 'swissunihockey/game',
      gamesHome: 'swissunihockey/nextHomeGames',
      team: 'swissunihockey/team',
      teams: 'swissunihockey/teams',
      leagueById: 'swissunihockey/leagueById'
    })
  },
  methods: {
    gameSelected (game) {
      this.$store.dispatch('swissunihockey/updateGame', game)
    },
    resetClub () {
      this.$store.dispatch('swissunihockey/resetClub')
      this.resetGame()
      this.reset()
    },
    resetGame () {
      this.$store.dispatch('swissunihockey/resetGame')
    },
    async teamSelected (team) {
      this.resetGame()
      await this.$store.dispatch('swissunihockey/updateTeam', team)
      this.$emit('onTeamSelected', team)
    },
    reset () {
      this.$emit('onResetBooklet')
    }
  }
}
</script>
