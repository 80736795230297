<template>
  <div>
    <div class="relative" v-if="booklet.published || isSameOrAfter(booklet)">
      <div :ref="'dropdown'" @click="isOpen = !isOpen" class="cursor-pointer flex items-center h-6 p-1">
        <svg class="fill-current dark:text-white" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M6 12c0 1.657-1.343 3-3 3s-3-1.343-3-3 1.343-3 3-3 3 1.343 3 3zm9 0c0 1.657-1.343 3-3 3s-3-1.343-3-3 1.343-3 3-3 3 1.343 3 3zm9 0c0 1.657-1.343 3-3 3s-3-1.343-3-3 1.343-3 3-3 3 1.343 3 3z"/></svg>
      </div>
      <div v-if="isOpen" class="absolute right-0 w-40 bg-white dark:bg-gray-900 rounded-lg shadow-xl z-50">
        <a href="#" v-if="isLoggedIn" @click="isOpen = false; $emit('onLoadBooklet', booklet)" class="flex items-center px-2 py-2 text-gray-800 dark:text-white hover:bg-gray-500 hover:text-white dark:hover:bg-gray-700 rounded-t-lg last:rounded-b-lg">
          <svg class="fill-current hover:text-white mr-2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M9 19h-4v-2h4v2zm2.946-4.036l3.107 3.105-4.112.931 1.005-4.036zm12.054-5.839l-7.898 7.996-3.202-3.202 7.898-7.995 3.202 3.201zm-6 8.92v3.955h-16v-20h7.362c4.156 0 2.638 6 2.638 6s2.313-.635 4.067-.133l1.952-1.976c-2.214-2.807-5.762-5.891-7.83-5.891h-10.189v24h20v-7.98l-2 2.025z"/></svg>
        {{$t('editor.edit')}}</a>
        <a href="#" v-if="booklet.published" @click="openPdf()" class="flex items-center px-2 py-2 text-gray-800 dark:text-white hover:bg-gray-500 hover:text-white dark:hover:bg-gray-700 first:rounded-t-lg">
          <svg class="fill-current hover:text-white mr-2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M11.363 2c4.155 0 2.637 6 2.637 6s6-1.65 6 2.457v11.543h-16v-20h7.363zm.826-2h-10.189v24h20v-14.386c0-2.391-6.648-9.614-9.811-9.614zm4.811 13h-2.628v3.686h.907v-1.472h1.49v-.732h-1.49v-.698h1.721v-.784zm-4.9 0h-1.599v3.686h1.599c.537 0 .961-.181 1.262-.535.555-.658.587-2.034-.062-2.692-.298-.3-.712-.459-1.2-.459zm-.692.783h.496c.473 0 .802.173.915.644.064.267.077.679-.021.948-.128.351-.381.528-.754.528h-.637v-2.12zm-2.74-.783h-1.668v3.686h.907v-1.277h.761c.619 0 1.064-.277 1.224-.763.095-.291.095-.597 0-.885-.16-.484-.606-.761-1.224-.761zm-.761.732h.546c.235 0 .467.028.576.228.067.123.067.366 0 .489-.109.199-.341.227-.576.227h-.546v-.944z"/></svg>
        {{$t('booklets.open_pdf')}}</a>
        <a href="#" v-if="booklet.published" @click="openQrCodePdf()" class="flex items-center px-2 py-2 text-gray-800 dark:text-white hover:bg-gray-500 hover:text-white dark:hover:bg-gray-700 first:rounded-t-lg">
          <svg class="fill-current hover:text-white mr-2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M11.363 2c4.155 0 2.637 6 2.637 6s6-1.65 6 2.457v11.543h-16v-20h7.363zm.826-2h-10.189v24h20v-14.386c0-2.391-6.648-9.614-9.811-9.614zm4.811 13h-2.628v3.686h.907v-1.472h1.49v-.732h-1.49v-.698h1.721v-.784zm-4.9 0h-1.599v3.686h1.599c.537 0 .961-.181 1.262-.535.555-.658.587-2.034-.062-2.692-.298-.3-.712-.459-1.2-.459zm-.692.783h.496c.473 0 .802.173.915.644.064.267.077.679-.021.948-.128.351-.381.528-.754.528h-.637v-2.12zm-2.74-.783h-1.668v3.686h.907v-1.277h.761c.619 0 1.064-.277 1.224-.763.095-.291.095-.597 0-.885-.16-.484-.606-.761-1.224-.761zm-.761.732h.546c.235 0 .467.028.576.228.067.123.067.366 0 .489-.109.199-.341.227-.576.227h-.546v-.944z"/></svg>
        {{$t('booklets.open_qrcode')}}</a>
        <a href="#" v-if="booklet.published" @click="openQrCode()" class="flex items-center px-2 py-2 text-gray-800 dark:text-white hover:bg-gray-500 hover:text-white dark:hover:bg-gray-700 rounded-b-lg">
          <svg class="fill-current hover:text-white mr-2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 2c1.654 0 3 1.346 3 3v14c0 1.654-1.346 3-3 3h-14c-1.654 0-3-1.346-3-3v-14c0-1.654 1.346-3 3-3h14zm0-2h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-8 8h-1v-2h1v1h2v1h-1v1h-1v-1zm2 12v-1h-1v1h1zm-1-15v-1h-2v1h1v1h1v-1zm8-1v6h-1v-1h-4v-5h5zm-1 4v-3h-3v3h3zm-14 2h-1v1h2v-1h-1zm0 3h1v1h1v-3h-1v1h-2v2h1v-1zm5 1v2h1v-2h-1zm4-10h-1v3h1v-3zm0 5v-1h-1v1h1zm3-2h1v-1h-1v1zm-10-1h-1v1h1v-1zm2-2v5h-5v-5h5zm-1 1h-3v3h3v-3zm9 5v1h-1v-1h-2v1h-1v-1h-3v-1h-1v1h-1v1h1v2h1v-1h1v2h1v-2h3v1h-2v1h2v1h1v-3h1v1h1v2h1v-1h1v-1h-1v-1h-1v-1h1v-1h-2zm-11 8h1v-1h-1v1zm-2-3h5v5h-5v-5zm1 4h3v-3h-3v3zm12-3v-1h-1v1h1zm0 1h-1v1h-1v-1h-1v-1h1v-1h-2v-1h-1v2h-1v1h-1v3h1v-1h1v-1h2v2h1v-1h1v1h2v-1h1v-1h-2v-1zm-9-3h1v-1h-1v1zm10 2v1h1v1h1v-3h-1v1h-1zm2 4v-1h-1v1h1zm0-8v-1h-1v1h1z"/></svg>
        {{$t('booklets.open_qrcode')}}</a>
      </div>
    </div>
  </div>
</template>

<script>
import ApiClient from '@/assets/js/ApiClient.js'
import { mapGetters } from 'vuex'
import dateMixin from '@/mixin/dateMixin.js'
import fileDownload from 'js-file-download'
import dayjs from 'dayjs'
var isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
dayjs.extend(isSameOrAfter)

export default {
  mixins: [dateMixin],
  props: {
    booklet: Object
  },
  data () {
    return {
      isOpen: false
    }
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn'
    })
  },
  methods: {
    openPdf () {
      this.isOpen = false
      ApiClient.get(`/api/su/booklets/${this.booklet.id}/download?typ=pdf`, { responseType: 'blob', headers: { Accept: 'application/pdf' } }).then(res => {
        const filename = `${this.formatDateDownload(this.booklet.game_datetime)}_vs_${this.booklet.away_team.replace(/ /g, '')}.pdf`
        fileDownload(res.data, filename)
      })
    },
    openQrCodePdf () {
      this.isOpen = false
      ApiClient.get(`/api/su/booklets/${this.booklet.id}/download?typ=qrcode_pdf`, { responseType: 'blob' }).then(res => {
        const filename = `QrCode_${this.formatDateDownload(this.booklet.game_datetime)}_vs_${this.booklet.away_team.replace(/ /g, '')}.pdf`
        fileDownload(res.data, filename)
      })
    },
    openQrCode () {
      this.isOpen = false
      ApiClient.get(`/api/su/booklets/${this.booklet.id}/download?typ=qrcode`, { responseType: 'blob' }).then(res => {
        const filename = `QrCode_${this.formatDateDownload(this.booklet.game_datetime)}_vs_${this.booklet.away_team.replace(/ /g, '')}.png`
        fileDownload(res.data, filename)
      })
    },
    close (e) {
      if (this.$refs.dropdown && !this.$refs.dropdown.contains(e.target)) {
        this.isOpen = false
      }
    },
    handleEscape (e) {
      if (e.key === 'Esc' || e.key === 'Escape') {
        this.isOpen = false
      }
    },
    isSameOrAfter (booklet) {
      return dayjs(booklet.game_datetime, 'YYYY-MM-DD HH:mm:ss').isSameOrAfter(dayjs(), 'day')
    }
  },
  created () {
    document.addEventListener('click', this.close)
    document.addEventListener('keydown', this.handleEscape)
  },
  beforeDestroy () {
    document.removeEventListener('click', this.close)
    document.removeEventListener('keydown', this.handleEscape)
  }
}
</script>
