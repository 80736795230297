require('./index.css').toString()

class AlingmentCenter {
  static get CSS () {
    return 'cdx-aligment-center'
  }

  static get isInline () {
    return true
  }

  get state () {
    return this._state
  }

  set state (state) {
    this._state = state
    this.button.classList.toggle(this.api.styles.inlineToolButtonActive, state)
  }

  constructor ({ api }) {
    this.api = api
    this.button = null
    this._state = false
    this.tag = 'SPAN'

    this.iconClasses = {
      base: this.api.styles.inlineToolButton,
      active: this.api.styles.inlineToolButtonActive
    }
  }

  render () {
    this.button = document.createElement('button')
    this.button.type = 'button'
    this.button.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path d="M24 3h-24v-2h24v2zm-6 3h-12v2h12v-2zm6 5h-24v2h24v-2zm-6 5h-12v2h12v-2zm6 5h-24v2h24v-2z"/></svg>'
    this.button.classList.add(this.iconClasses.base)
    return this.button
  }

  surround (range) {
    if (!range) {
      return
    }
    const termWrapper = this.api.selection.findParentTag(this.tag, AlingmentCenter.CSS)

    if (termWrapper) {
      this.unwrap(termWrapper)
    } else {
      this.wrap(range)
    }
  }

  wrap (range) {
    const marker = document.createElement(this.tag)
    marker.classList.add(AlingmentCenter.CSS)
    marker.appendChild(range.extractContents())
    range.insertNode(marker)
    this.api.selection.expandToTag(marker)
  }

  unwrap (termWrapper) {
    this.api.selection.expandToTag(termWrapper)

    const sel = window.getSelection()
    const range = sel.getRangeAt(0)

    const unwrappedContent = range.extractContents()
    termWrapper.parentNode.removeChild(termWrapper)
    range.insertNode(unwrappedContent)

    sel.removeAllRanges()
    sel.addRange(range)
  }

  checkState (selection) {
    const termTag = this.api.selection.findParentTag(this.tag, AlingmentCenter.CSS)
    this.button.classList.toggle(this.iconClasses.active, !!termTag)
  }

  static get sanitize () {
    return {
      span: {
        class: AlingmentCenter.CSS
      }
    }
  }
}

export default AlingmentCenter
