import { create, getCoords } from '../documentUtils'
import { ToolBar } from './toolBar'

const CSS = {
  active: 'active',
  hidden: 'invisible',
  table: 'players-table'
}

export class Table {
  constructor (api, objects, title = null, groupedCellKey = null, cellKeys = null, css = null) {
    this.api = api
    this.cellKeys = cellKeys
    this.activeRow = null
    this.timer = null
    this.objects = objects
    this.groupedCellKey = groupedCellKey
    var childs = []
    if (title) {
      var titleEl = create('h6')
      titleEl.innerHTML = title
      childs.push(titleEl)
    }
    var cssClasses = [CSS.table]
    if (css) {
      cssClasses.push(css)
    }
    this.table = create('table', cssClasses)
    childs.push(this.table)
    // create toolBar
    var emptyObject = {}
    Object.keys(this.objects[0]).forEach(key => { emptyObject[key] = '' })
    this.toolBar = new ToolBar(api, emptyObject)
    this.toolBar.htmlElement.addEventListener('toolbarBtnClicked', (event) => {
      this.table.innerHTML = ''
      this._updateContent()
      if (event.detail.index !== null) {
        const row = this.table.rows[event.detail.index]
        this._updateActiveRow(row)
        if (event.detail.open) {
          this.toolBar.open()
        }
      } else {
        this.toolBar.hide()
      }
    })

    this.toolBar.htmlElement.addEventListener('click', (event) => {
      clearTimeout(this.clearTimer)
    })
    // create wrapper
    this.tableWrapper = create('div', null, null, childs)
    this._updateContent()
  }

  get htmlElement () {
    return this.tableWrapper
  }

  rendered () {
    const editor = this.tableWrapper.closest('.container-editor')
    editor.appendChild(this.toolBar.htmlElement)
    // eventlistener
    const block = this.tableWrapper.closest('.ce-block')
    block.addEventListener('click', (event) => {
      if (event.toElement.closest('table') !== this.table) {
        this._clearUI()
      }
    })

    this.tableWrapper.addEventListener('click', (event) => {
      this._updateActiveRow(event.target.closest('TR'))
    })
  }

  _clearUI () {
    if (this.activeRow) {
      this.activeRow.classList.remove(CSS.active)
      this.activeRow = null
    }
    this.toolBar.hide()
  }

  _updateContent () {
    if (this.groupedCellKey) {
      var lastValue = ''
    }
    this.objects.forEach((object, index) => {
      const row = this.table.insertRow(-1)
      this.cellKeys.forEach(key => {
        if (this.groupedCellKey === key) {
          var cell
          if ((object[this.groupedCellKey] !== '') && (object[this.groupedCellKey] !== lastValue)) {
            lastValue = object[key]
            cell = this._addCell(row, key, object[key], 'font-bold')
            if (index > 0) {
              row.classList.add('spaceUp')
            }
          } else {
            cell = this._addCell(row, key, '')
          }
          if (object.topscorer) {
            cell.classList.add('topscorer')
          }
        } else {
          this._addCell(row, key, object[key])
        }
      })
    })
  }

  _addCell (row, key, value, css = '') {
    var cell = row.insertCell(-1)
    cell.innerHTML = value
    if (css !== '') {
      cell.classList.add(css)
    }
    cell.contentEditable = 'true'
    cell.addEventListener('input', (e) => {
      if (key === this.groupedCellKey) {
        if (e.target.innerHTML === '') {
          cell.classList.remove('font-bold')
          row.classList.remove('spaceUp')
        } else {
          cell.classList.add('font-bold')
          row.classList.add('spaceUp')
        }
      }
      this.objects[row.rowIndex][key] = e.target.innerHTML
    })
    cell.addEventListener('focusout', (e) => {
      this.clearTimer = setTimeout(() => { this._clearUI() }, 100)
    })
    return cell
  }

  _updateActiveRow (currentRow) {
    clearTimeout(this.clearTimer)
    if (!currentRow) {
      return
    }
    if (this.activeRow) {
      this.activeRow.classList.remove(CSS.active)
    }
    this.activeRow = currentRow
    const table = this.activeRow.closest('.table-container')
    const cords = getCoords(this.activeRow, this.toolBar.htmlElement.parentElement)
    this.toolBar.show(cords, table.classList.contains('players-wrapper-right'), this.objects, this.activeRow.rowIndex)
    this.activeRow.classList.add(CSS.active)
  }
}
