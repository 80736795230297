var axios = require('axios')

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.accessToken
    if (token) {
      config.headers = { Authorization: `Bearer ${token}` }
    }
    config.headers['Content-Type'] = 'application/json'
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const originalRequest = error.config
    if (error.response.status === 401 && !originalRequest._retry && localStorage.refreshToken) {
      originalRequest._retry = true
      localStorage.removeItem('accessToken')
      const refreshToken = localStorage.refreshToken
      if (!refreshToken) { return }
      return axiosInstance
        .post('api/refresh_token', { refresh: refreshToken })
        .then((response) => {
          localStorage.setItem('accessToken', response.data.access_token)
          localStorage.setItem('refreshToken', response.data.refresh_token)
          return axiosInstance(originalRequest)
        })
    }
    return Promise.reject(error)
  }
)
export default axiosInstance
