import { Table } from './table'
import { create } from '../documentUtils'
import store from '@/store'
import { i18n } from '@/main.js'

const CSS = {
  flex: 'flex',
  wrapperLeft: 'players-wrapper-left',
  wrapperRight: 'players-wrapper-right',
  tableContainer: 'table-container'
}

class PlayersTable {
  constructor ({ data, api }) {
    const emptyStaff = [{ title: 'Betreuer', name: '...Name...', typ: 'Cheftrainer' }]
    this.tables = []
    this.data = data
    this.api = api
    this.team = this.clone(store.getters['swissunihockey/team'])
    this.awayTeam = this.clone(store.getters['swissunihockey/awayTeam'])
    this.nameHome = this.team ? this.team.name : ''
    this.nameAway = this.awayTeam ? this.awayTeam.name : ''
    this.playersHome = this.data && this.data.playersHome ? this.data.playersHome : this.team ? this.team.players : [{ position: 'Position', nr: '99', name: '<Name>', year: '1999' }]
    this.playersAway = this.data && this.data.playersAway ? this.data.playersAway : this.awayTeam ? this.awayTeam.players : [{ position: 'Position', nr: '99', name: '<Name>', year: '1999' }]
    this.staffHome = this.data && this.data.staffHome ? this.data.staffHome : this.team ? this.team.staff : emptyStaff
    this.staffAway = this.data && this.data.staffAway ? this.data.staffAway : this.awayTeam ? this.awayTeam.staff : emptyStaff
    this.staffHome = this.staffHome || emptyStaff
    this.staffAway = this.staffAway || emptyStaff
    this.wrapper = undefined
    this.hoveredRow = null
  }

  clone (value) {
    return JSON.parse(JSON.stringify(value))
  }

  static get toolbox () {
    return {
      title: i18n.tc('editorjs.players_table.title'),
      icon: '<svg class="h-12 w-12 p-1 fill-current" viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg"><path d="M113.6 311c-30.2 0-54.8-24.6-54.8-54.8 0-30.2 24.6-54.8 54.8-54.8s54.8 24.6 54.8 54.8c0 30.3-24.6 54.8-54.8 54.8zm0-82.1c-15.1 0-27.4 12.3-27.4 27.4 0 15.1 12.3 27.4 27.4 27.4s27.4-12.3 27.4-27.4c0-15.1-12.3-27.4-27.4-27.4zM346.3 365.8h-27.4c0-37.7-30.7-68.4-68.4-68.4s-68.4 30.7-68.4 68.4h-27.4c0-52.8 43-95.8 95.8-95.8s95.8 43 95.8 95.8zM387.4 311c-30.2 0-54.8-24.6-54.8-54.8 0-30.2 24.6-54.8 54.8-54.8 30.2 0 54.8 24.6 54.8 54.8-.1 30.3-24.6 54.8-54.8 54.8zm0-82.1c-15.1 0-27.4 12.3-27.4 27.4 0 15.1 12.3 27.4 27.4 27.4 15.1 0 27.4-12.3 27.4-27.4 0-15.1-12.3-27.4-27.4-27.4z"/><path d="M250.5 297.4c-37.7 0-68.4-30.7-68.4-68.4s30.7-68.4 68.4-68.4 68.4 30.7 68.4 68.4-30.7 68.4-68.4 68.4zm0-109.5c-22.6 0-41.1 18.4-41.1 41.1s18.4 41.1 41.1 41.1 41.1-18.4 41.1-41.1-18.5-41.1-41.1-41.1zM346.3 365.8h-27.4v-13.7c0-37.7 30.7-68.4 68.4-68.4s68.4 30.7 68.4 68.4h-27.4c0-22.6-18.4-41.1-41.1-41.1s-41.1 18.4-41.1 41.1v13.7zM182.1 365.8h-27.4v-13.7c0-22.6-18.4-41.1-41.1-41.1s-41.1 18.4-41.1 41.1H45.2c0-37.7 30.7-68.4 68.4-68.4s68.4 30.7 68.4 68.4v13.7z"/></svg>'
    }
  }

  render () {
    this._createTables()
    return this.wrapper
  }

  rendered () {
    this.tables.forEach(t => t.rendered())
  }

  save (blockContent) {
    return {
      playersHome: this.playersHome,
      playersAway: this.playersAway,
      staffHome: this.staffHome,
      staffAway: this.staffAway
    }
  }

  _refreshData () {
    this.wrapper.innerHTML = ''
    this._createTables()
  }

  _createTables () {
    var wrapperLeft, wrapperRight, table1, table2
    if (!this.playersHome) {
      this.wrapper = create('div', [CSS.flex], null, [])
      this.wrapper.innerHTML = i18n.tc('editorjs.players_table.no_data')
      return this.wrapper
    }
    table1 = new Table(this.api, this.playersHome, this.nameHome, 'position', ['position', 'nr', 'name', 'year'])
    table2 = new Table(this.api, this.staffHome, null, 'title', ['title', 'name', 'typ'], 'staff')
    this.tables.push(table1)
    this.tables.push(table2)
    wrapperLeft = create('div', [CSS.tableContainer, CSS.wrapperLeft], null, [table1.htmlElement, table2.htmlElement])
    table1 = new Table(this.api, this.playersAway, this.nameAway, 'position', ['position', 'nr', 'name', 'year'])
    table2 = new Table(this.api, this.staffAway, null, 'title', ['title', 'name', 'typ'], 'staff')
    this.tables.push(table1)
    this.tables.push(table2)
    wrapperRight = create('div', [CSS.tableContainer, CSS.wrapperRight], null, [table1.htmlElement, table2.htmlElement])
    this.wrapper = create('div', [CSS.flex], null, [wrapperLeft, wrapperRight])
    return this.wrapper
  }
}

export default PlayersTable
