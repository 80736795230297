<style>
.fix-height {
  height: calc(100vh - theme('spacing[24]'));
}
</style>
<template>
  <div>
    <div class="flex z-10 gap-4 justify-center 2xl:container 2xl:mx-auto">
     <div ref="menu" class="flex-grow z-30 dark:bg-black w-full ml-4"  :class="[showMenu ? 'absolute w-full' : 'hidden md:block']">
        <div class="max-h-full overflow-y-auto bg-white dark:bg-gray-900 rounded-md shadow p-3 pb-6 scrollbar sticky top-20">
          <div class="p-1 bg-white dark:bg-gray-900 rounded-md w-full">
            <template v-if="hasPages">
              <SuNavigation v-if="!showPreview" @onResetBooklet="resetBooklet"></SuNavigation>
              <button class="primary w-full px-3 mb-4" v-if="!showPreview" @click="preview()">{{$t('preview')}}</button>
              <button class="w-full" v-if="showPreview" @click="downloadPdf()">{{$t('download')}}</button>
              <button class="primary w-full mt-2" v-if="showPreview && isLoggedIn" @click="onPublishBooklet()">{{$t('publish')}}</button>
              <button class="w-full mt-4" v-if="showPreview" @click="resetPreview()">{{$t('back')}}</button>
            </template>
            <template v-else>
              <div v-if="!isLoggedIn" class="dark:text-white">
              <div class="text-xl xl:text-2xl font-semibold uppercase">Der Online-Editor für dein Matchheft</div>
              <p class="pt-5">
                Ohne zu registieren kannst du ein Matchheft gestalten und herunterladen. Suche nach deinem Verein und leg los!
              </p>
              <div class="bg-gradient-to-tr from-teal-500 to-teal-300 dark:from-teal-700 dark:to-teal-500 p-3 mt-8 rounded relative text-white">
                <div class="flex">
                  <svg class="fill-current text-yellow-400 mr-2 mt-1 h-5 w-5 xl:h-6 xl:w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M3 16l-3-10 7.104 4 4.896-8 4.896 8 7.104-4-3 10h-18zm0 2v4h18v-4h-18z"/></svg>
                  <div class="text-xl xl:text-2xl font-semibold uppercase">Premium</div>
                  <svg class="fill-current text-yellow-400 ml-2 mt-1 h-5 w-5 xl:h-6 xl:w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M3 16l-3-10 7.104 4 4.896-8 4.896 8 7.104-4-3 10h-18zm0 2v4h18v-4h-18z"/></svg>
                </div>
                <p class="py-3">
                 Für nur 15.- monatlich oder 150.- jährlich profitierst du von:
                </p>
                <div class="text-xl font-semibold mt-2">Matchhefter verwalten</div>
                <p class="py-3">
                  Steht ein nächstes Heimspiel an? Nimm einfach die Vorlage aus dem vorderen Heimspiel und passe den Text an.
                </p>
                <div class="text-xl font-semibold mt-2">Kaderlisten Verwaltung</div>
                <p class="py-3">
                  Passe dein Kader laufend an. Damit dein Gegner immer die dein aktuelles Kader zur verfügung hat.
                </p>
                <div class="text-xl font-semibold mt-4">PDF und QR-Code</div>
                <p class="pt-3">
                 Spare Papier und drucke nur der QR-Code aus, schon können die Matchbesucher das Matchheft bequem auf ihr Smartphone laden.
                </p>
                </div>
              </div>
              <div v-else class="dark:text-white w-full">
                <div class="text-2xl font-semibold uppercase">Tipps & Tricks</div>
                <p class="py-3">
                  Hier werden bald Tipps zum Matchheft - Editor gezeigt. <br><br> Hast du Fragen oder ein Fehler gefunden? Melde dich unter <a href="mailto:info@matchheft.ch">info@matchheft.ch</a>
                </p>
              </div>
            </template>
          </div>
          <Footer class="fixed bottom-0 left-0 text-left"></Footer>
        </div>
      </div>
      <div class="flex-grow-0 flex-shrink-0 z-10 mr-4 lg:mr-0">
        <template v-if="!hasPages">
        <SuNewBooklet @onUpdateMatchheft="updateMatchheft"></SuNewBooklet>
        </template>
        <div v-else v-show="!showPreview" class="flex flex-col">
          <Editor v-for="(page, index) in pages"
            :key="page.key"
            :pageNr="index + 1"
            :blocks="page.blocks"
            @onDeletePage="onDeletePage"
            @onUpdateData="onUpdateData"
            :style="'font-family: ' + fontFamily + ', serif;'"
          >
            <template v-slot:toolbar="props">
              <SuToolbar :editor="props.editor"></SuToolbar>
              <FontSelector class="mr-2" @onFontChanged="onFontChanged"></FontSelector>
            </template>
          </Editor>
          <div @click="addPage()" class="add-page">
            <span>{{$t('add_page')}}</span>
          </div>
        </div>
        <Preview v-show="showPreview" :pages="previewPages"></Preview>
      </div>
      <div ref="booklets" class="hidden lg:block flex-grow z-30 dark:bg-black w-full mr-4 fix-height sticky top-20">
        <div class="w-full max-h-full overflow-y-auto bg-white dark:bg-gray-900 rounded-md shadow p-3 pb-6 scrollbar">
          <Booklets @onUpdateMatchheft="updateMatchheft"></Booklets>
        </div>
      </div>
      <Loading :visible="isLoading" :progress="progress" :message="loadingMessage"></Loading>
    </div>
    <AlertSucceed :show="saved" :message="$t('booklet_published')"></AlertSucceed>
  </div>
</template>

<script>
import JsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import Editor from '@/components/Editor'
import FontSelector from '@/components/FontSelector'
import Loading from '@/components/Loading'
import SuToolbar from '@/components/swissunihockey/SuToolbar'
import SuNavigation from '@/components/swissunihockey/SuNavigation'
import SuNewBooklet from '@/components/swissunihockey/SuNewBooklet'
import Preview from '@/components/Preview'
import Booklets from '@/components/Booklets'
import { mapGetters, mapActions } from 'vuex'
import Footer from '@/components/Footer'
import AlertSucceed from '@/components/AlertSucceed'

const shortid = require('shortid')

export default {
  name: 'app',
  components: {
    AlertSucceed,
    Editor,
    FontSelector,
    SuNavigation,
    SuToolbar,
    SuNewBooklet,
    Preview,
    Loading,
    Booklets,
    Footer
  },
  props: {
    showMenu: Boolean
  },
  data () {
    return {
      activeFontFamily: null,
      pages: [{ blocks: {} }],
      previewPages: [],
      showPreview: false,
      isLoading: false,
      progress: 0.0,
      loadingMessage: '',
      saved: false
    }
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      bookletByGameId: 'swissunihockey/bookletByGameId',
      game: 'swissunihockey/game'
    }),
    fontFamily () {
      return this.activeFontFamily || 'Roboto'
    },
    hasPages () {
      return this.pages.length > 0
    }
  },
  methods: {
    ...mapActions({
      saveBooklet: 'swissunihockey/saveBooklet',
      publishBooklet: 'swissunihockey/publishBooklet'
    }),
    addPage () {
      this.pages.push({ blocks: {}, key: shortid.generate() })
    },
    onDeletePage (index) {
      this.pages.splice(index, 1)
    },
    onUpdateData (index, blocks) {
      this.pages[index].blocks = blocks
      this.save()
    },
    onFontChanged (font) {
      this.activeFontFamily = font
      this.save()
    },
    async preview () {
      const step = 100 / this.pages.length
      this.showLoading('Vorschau wird erstellt...')
      window.scrollTo(0, 0)
      setTimeout(async () => {
        for (var index = 0; index < this.pages.length; index++) {
          this.progress += step
          const page = document.getElementById(`codex-editor-${index + 1}`).childNodes[2].childNodes[0]
          const canvas = await html2canvas(page, {
            scale: 2,
            useCORS: true,
            onclone: (document) => {
              const markers = document.querySelectorAll('.cdx-template-marker')
              markers.forEach(marker => { marker.style.background = 'none' })
              const images = document.querySelectorAll('.no-image')
              images.forEach(img => { img.style.background = 'none' })
            }
          })
          this.previewPages.push(canvas.toDataURL('image/png'))
        }
        this.hideLoading()
        this.showPreview = true
      }, 50)
    },
    resetPreview () {
      this.showPreview = false
      this.previewPages = []
    },
    load (index) {
      this.onUpdateMatchheft(this.savedPages[index])
    },
    save () {
      if (this.isLoggedIn) {
        this.saveBooklet(this.pages.map(p => Array.isArray(p.blocks) ? { blocks: {} } : p.blocks))
      }
    },
    resetBooklet () {
      this.pages = []
    },
    onPublishBooklet () {
      const booklet = this.bookletByGameId(this.game.id)
      var payload = {}
      payload.booklet = booklet
      const step = 100 / this.pages.length
      this.showLoading('Matchheft veröffentlichen...')
      setTimeout(async () => {
        var pdf = new JsPDF({ format: [210, 148], compressPdf: true })
        var width = pdf.internal.pageSize.getWidth()
        var height = pdf.internal.pageSize.getHeight()
        this.previewPages.forEach((imgData, index) => {
          this.progress += step
          pdf.addImage(imgData, 'png', 0, 0, width, height)
          if (index < this.pages.length - 1) {
            pdf.addPage()
          }
        })
        payload.pdf = pdf.output('blob')
        this.publishBooklet(payload)
        this.showPreview = false
        this.previewPages = []
        this.hideLoading()
        this.saved = true
        setTimeout(async () => {
          this.saved = false
        }, 3000)
      }, 50)
    },
    downloadPdf () {
      const step = 100 / this.pages.length
      this.showLoading('Matchheft wird erstellt...')
      setTimeout(async () => {
        var pdf = new JsPDF({ format: [210, 148], compressPdf: true })
        var width = pdf.internal.pageSize.getWidth()
        var height = pdf.internal.pageSize.getHeight()
        this.previewPages.forEach((imgData, index) => {
          this.progress += step
          pdf.addImage(imgData, 'png', 0, 0, width, height)
          if (index < this.pages.length - 1) {
            pdf.addPage()
          }
        })
        pdf.save('matchheft.pdf')
        this.showPreview = false
        this.previewPages = []
        this.hideLoading()
      }, 50)
    },
    showLoading (message) {
      document.documentElement.style.overflow = 'hidden'
      this.loadingMessage = message
      this.isLoading = true
    },
    hideLoading () {
      document.documentElement.style.overflow = 'auto'
      this.isLoading = false
      this.progress = 0
    },
    updateMatchheft (blocks) {
      this.pages = []
      blocks.forEach(block => {
        this.addPage()
        const index = this.pages.length - 1
        this.pages[index].blocks = block
      })
      window.scrollTo(0, 0)
      this.save()
      const booklet = this.bookletByGameId(this.game.id)
      if (booklet) {
        this.onFontChanged(booklet.font)
        this.$store.commit('updateFont', booklet.font)
      }
    }
  },
  watch: {
    game: {
      immediate: true,
      handler (oldValue, newValue) {
        if (this.game) {
          const booklet = this.bookletByGameId(this.game.id)
          if (booklet !== undefined) {
            setTimeout(async () => {
              this.updateMatchheft(booklet.blocks[0].length === undefined ? booklet.blocks : [{}])
            }, 100)
          } else {
            this.pages = []
          }
        } else {
          this.pages = []
        }
      }
    }
  }
}
</script>
