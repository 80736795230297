import dayjs from 'dayjs'

export default {
  methods: {
    formatDate (date) {
      return dayjs(date, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY HH:mm')
    },
    formatDateDownload (date) {
      return dayjs(date, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY')
    }
  }
}
