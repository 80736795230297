/**
 * Checks the item is not missed or messed
 * @param {object|string[]|Element[]|HTMLElement|string} elem - element
 * @returns {boolean} true if element is correct
 * @private
 */
function _isNotMissed (elem) {
  return (!(elem === undefined || elem === null))
}

/**
 * Create DOM element with set parameters
 * @param {string} tagName - Html tag of the element to be created
 * @param {string[]} cssClasses - Css classes that must be applied to an element
 * @param {object} attrs - Attributes that must be applied to the element
 * @param {Element[]} children - child elements of creating element
 * @returns {HTMLElement} the new element
 */
export function create (tagName, cssClasses = null, attrs = null, children = null) {
  const elem = document.createElement(tagName)

  if (_isNotMissed(cssClasses)) {
    for (let i = 0; i < cssClasses.length; i++) {
      if (_isNotMissed(cssClasses[i])) {
        elem.classList.add(cssClasses[i])
      }
    }
  }
  if (_isNotMissed(attrs)) {
    for (const key in attrs) {
      elem.setAttribute(key, attrs[key])
    }
  }
  if (_isNotMissed(children)) {
    for (let i = 0; i < children.length; i++) {
      if (_isNotMissed(children[i])) {
        elem.appendChild(children[i])
      }
    }
  }
  return elem
}

export function getCoords (elem, parent) {
  const rect = elem.getBoundingClientRect()
  const rectParent = parent.getBoundingClientRect()
  return {
    y1: Math.floor(rect.top - rectParent.top),
    x1: Math.floor(rect.left - rectParent.left),
    x2: Math.floor(rect.right - rectParent.left),
    y2: Math.floor(rect.bottom + rectParent.bottom)
  }
}
