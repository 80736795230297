<style>
  .icon-delete {
    @apply bg-gray-300 hover:bg-gray-400 dark:bg-gray-700 dark:hover:bg-gray-800;
  }
</style>

<template>
<div class="flex-shrink-0 cursor-pointer rounded" :class="[confirmDelete ? 'transition duration-500 ease-in-out bg-red-600 hover:bg-red-700':  'icon-delete']">
  <svg @click="deleteClicked()" class="cursor-pointer fill-current p-2 h-8 w-8 dark:text-gray-300" :class="[ confirmDelete ? 'text-white duration-500 transform ease-in-out rotate-180' : 'text-gray-800']" viewBox="0 0 237 237" xmlns="http://www.w3.org/2000/svg"><path d="M118.5 86.68L201.94 3.242c3.905-3.906 10.236-3.906 14.142 0l17.677 17.677c3.906 3.906 3.906 10.237 0 14.143L150.32 118.5l83.438 83.439c3.906 3.905 3.906 10.237 0 14.142l-17.677 17.678c-3.906 3.905-10.237 3.905-14.142 0l-83.439-83.44-83.439 83.44c-3.905 3.905-10.236 3.905-14.142 0L3.242 216.08c-3.906-3.905-3.906-10.237 0-14.142L86.68 118.5 3.242 35.062c-3.906-3.906-3.906-10.237 0-14.143L20.919 3.242c3.906-3.906 10.237-3.906 14.142 0l83.44 83.438z"/></svg>
</div>
</template>

<script>
export default {
  name: 'deleteButton',
  props: {
    pageNr: Number,
    data: Object
  },
  data () {
    return {
      confirmDelete: false
    }
  },
  methods: {
    deleteClicked () {
      if (this.confirmDelete) {
        this.$emit('onDelete')
        this.confirmDelete = false
      } else {
        this.confirmDelete = true
      }
    }
  }
}

</script>
