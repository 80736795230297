<template>
  <div>
    <div
      v-if="!isOpen"
      @click="isOpen = true"
      class="cursor-pointer fixed bottom-0 right-0 border-t-2 border-l-2 border-gray-300 dark:border-gray-700 p-2 bg-white dark:bg-black hover:bg-gray-200 dark:hover:bg-gray-800 px-4 rounded-t text-black dark:text-white"
    >
      Feedback
    </div>
    <div
      v-else
      class="fixed bottom-0 right-0 bg-white dark:bg-black dark:border-gray-600 border-t-2 border-l-2 w-64 p-4 dark:text-white"
    >
      <div @click="isOpen = false" class="w-full cursor-pointer font-bold">
        Feedback
      </div>

      <div>
        <p class="py-4">
          {{ $t('feedback-text') }}
        </p>
        <form @submit.prevent="sendFeedback">
          <input
            v-if="user"
            :value="user.email"
            readonly
            required
            disabled
            class="border border-gray-500 bg-gray-100 dark:bg-gray-900 dark:text-gray-500 rounded-none text-sm focus:ring-1 focus:ring-teal-500"
            type="email"
            placeholder="Deine E-Mail"
          />
          <input
            v-else
            v-model="email"
            required
            class="border border-gray-500 bg-gray-100 dark:bg-gray-900 rounded-none text-sm focus:ring-1 focus:ring-teal-500"
            type="email"
            placeholder="Deine E-Mail"
          />
          <textarea
            v-model="note"
            required
            class="outline-none border border-gray-500 bg-gray-100 dark:bg-gray-900 rounded-none p-2 text-sm my-3 w-full h-32 focus:ring-1 focus:ring-teal-500 resize-none"
            placeholder="Deine Mitteilung"
            maxlength="1024"
          >
          </textarea>
          <button
            type="submit"
            class="bg-teal-600 text-white hover:bg-teal-400 w-full"
          >
            <div v-if="succeed" class="flex w-full justify-center">
              <svg class="h-6 fill-current text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.25 16.518l-4.5-4.319 1.396-1.435 3.078 2.937 6.105-6.218 1.421 1.409-7.5 7.626z"/></svg>
            </div>
            <span v-else class="flex items-center justify-center">
              <svg v-if="saved" class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              <span>{{ $t('send-feedback') }}</span>
            </span>
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import ApiClient from '@/assets/js/ApiClient.js'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      isOpen: false,
      email: '',
      note: '',
      saved: false,
      succeed: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'user'
    })
  },
  methods: {
    sendFeedback () {
      this.saved = true
      if (this.user) {
        this.email = this.user.email
      }
      ApiClient.post('/api/feedback', { email: this.email, notes: this.note }).then(_ => {
        this.succeed = true
        this.saved = false
        this.note = ''
        this.email = ''
        setTimeout(async () => {
          this.isOpen = false
          this.succeed = false
        }, 1000)
      })
    }
  }
}
</script>
