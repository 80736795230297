<style>
.codex-editor__redactor {
  @apply bg-white rounded overflow-hidden border border-gray-300;
  height: 210mm;
  width: 148mm;
}

.container-editor {
  min-width: 609.359px;
  min-height: 857.688px;

}
.error-text {
  @apply leading-tight text-red-700 text-sm;
  font-family: 'Roboto'
}
</style>

<template>
  <div class="pl-12 flex flex-col items-center mb-5">
  <div :id="editorId" :ref="editorId" class="container-editor relative">
    <div class="mb-2 pr-12">
    <div class="bg-white dark:bg-gray-900 flex justify-between items-center p-2 rounded-md shadow">
      <slot name="toolbar" v-bind:editor="editor"></slot>
      <div v-if="(this.pageNr > 1)" ref="deletePage" class="h-10 w-10 cursor-pointer rounded bg-white" :class="[confirmDelete ? 'transition duration-500 ease-in-out bg-red-600 hover:bg-red-700':  'bg-gray-300 hover:bg-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700']" @click="deletePage()">
      <svg class="h-10 w-10 p-3 fill-current dark:text-white" :class="[ confirmDelete ? 'text-white duration-500 transform ease-in-out rotate-180' : 'text-gray-800']" viewBox="0 0 237 237" xmlns="http://www.w3.org/2000/svg"><path d="M118.5 86.68L201.94 3.242c3.905-3.906 10.236-3.906 14.142 0l17.677 17.677c3.906 3.906 3.906 10.237 0 14.143L150.32 118.5l83.438 83.439c3.906 3.905 3.906 10.237 0 14.142l-17.677 17.678c-3.906 3.905-10.237 3.905-14.142 0l-83.439-83.44-83.439 83.44c-3.905 3.905-10.236 3.905-14.142 0L3.242 216.08c-3.906-3.905-3.906-10.237 0-14.142L86.68 118.5 3.242 35.062c-3.906-3.906-3.906-10.237 0-14.143L20.919 3.242c3.906-3.906 10.237-3.906 14.142 0l83.44 83.438z"/></svg>
      </div>
    </div>
  </div>
    <div v-if="isWarning" class="flex absolute bottom-0 right-0 z-50 items-center mb-4 mr-16 bg-red-200 p-3 border-l-8 border-red-700 rounded w-1/2">
      <svg class="flex-none fill-current text-red-700 mr-2 w-8 h-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M16.971 0h-9.942l-7.029 7.029v9.941l7.029 7.03h9.941l7.03-7.029v-9.942l-7.029-7.029zm-4.971 19.25c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25zm.5-4.25h-1l-1-10h3l-1 10z"/></svg>
      <div class="error-text">{{$t('editor.warning_out_of_bounds')}}</div>
    </div>
  </div>
</div>
</template>

<script>
import EditorJS from '@editorjs/editorjs'
import Header from '@editorjs/header'
import Paragraph from '@editorjs/paragraph'
import RankingTable from '@/assets/js/editorjs/ranking-table'
import VsTable from '@/assets/js/editorjs/vs-table'
import Topscorer from '@/assets/js/editorjs/topscorer'
import GamesTable from '@/assets/js/editorjs/games-table'
import PlayersTable from '@/assets/js/editorjs/players-table/players-table'
import ImagesPlugin from '@/assets/js/editorjs/images'
import AligmentTools from '@/assets/js/editorjs/alingment-tools'
import TemplateMarker from '@/assets/js/editorjs/template-marker'
import Tooltip from 'codex-tooltip'

export default {
  components: {
  },
  props: {
    pageNr: Number,
    blocks: Object
  },
  data () {
    return {
      editor: null,
      confirmDelete: false,
      warning: false
    }
  },
  computed: {
    editorId () {
      return 'codex-editor-' + this.pageNr
    },
    isWarning () {
      return this.warning
    },
    currentBlock () {
      return this.editor.blocks.getCurrentBlockIndex()
    }
  },
  methods: {
    deletePage () {
      if (this.confirmDelete) {
        this.$emit('onDeletePage', this.pageNr - 1)
        this.confirmDelete = false
      } else {
        this.confirmDelete = true
      }
    },
    checkOutOfBounds () {
      if (this.$refs[this.editorId] === undefined) {
        return
      }
      const editor = this.$refs[this.editorId].querySelector('.codex-editor__redactor')
      const blocks = editor.childNodes
      var lastBlock = null
      for (var i = blocks.length - 1; i > -1; i--) {
        if (blocks[i].lastChild.lastChild.innerHTML !== '') {
          lastBlock = blocks[i]
          break
        }
      }
      if (lastBlock) {
        this.warning = (lastBlock.offsetTop + lastBlock.clientHeight) > editor.clientHeight
      }
    }
  },
  mounted () {
    this.editor = new EditorJS({
      holder: `codex-editor-${this.pageNr}`,
      tools: {
        template_marker: {
          class: TemplateMarker
        },
        alignment_center: {
          class: AligmentTools
        },
        paragraph: {
          class: Paragraph,
          inlineToolbar: ['bold', 'italic', 'alignment_center', 'template_marker'],
          config: {
            preserveBlank: true
          }
        },
        header: {
          class: Header,
          inlineToolbar: ['bold', 'italic', 'alignment_center', 'template_marker']
        },
        images: {
          class: ImagesPlugin,
          inlineToolbar: ['bold', 'italic']
        },
        rankingTable: {
          class: RankingTable,
          inlineToolbar: ['bold', 'italic']
        },
        vsTable: {
          class: VsTable,
          inlineToolbar: ['bold', 'italic']
        },
        topScorer: {
          class: Topscorer
        },
        gamesTable: {
          class: GamesTable,
          inlineToolbar: ['bold', 'italic']
        },
        playersTable: {
          class: PlayersTable,
          inlineToolbar: ['bold', 'italic']
        }
      },
      i18n: {
        messages: {
          ui: {
            blockTunes: {
              toggler: {
                'Click to tune': this.$t('editor.edit'),
                'or drag to move': this.$t('editor.or_drag_to_move')
              }
            },
            inlineToolbar: {
              converter: {
                'Convert to': this.$t('editor.convert_to')
              }
            },
            toolbar: {
              toolbox: {
                Add: this.$t('editor.add')
              }
            }
          },
          toolNames: {
            Text: this.$t('editor.text'),
            Heading: this.$t('editor.header'),
            Bold: this.$t('editor.bold'),
            Italic: this.$t('editor.italic'),
            Alignment_center: this.$t('editor.center'),
            Template_marker: this.$t('editor.template_marker')
          },
          tools: {
          },
          blockTunes: {
            delete: {
              Delete: this.$t('editor.delete')
            },
            moveUp: {
              'Move up': this.$t('editor.move_up')
            },
            moveDown: {
              'Move down': this.$t('editor.move_down')
            }
          }
        }
      },
      onReady: (editor) => {
        if (Object.keys(this.blocks).length !== 0) {
          this.editor.blocks.render(this.blocks)
        }
        setTimeout(async () => {
          this.checkOutOfBounds()
        }, 750)
      },
      onChange: (editor) => {
        editor.saver.save().then(savedData => {
          this.$emit('onUpdateData', this.pageNr - 1, savedData)
        })
        this.checkOutOfBounds()
      }
    })
    if (this.$refs.deletePage) {
      const tooltip = new Tooltip()
      tooltip.onHover(this.$refs.deletePage, this.$t('editor.deletePage'), {
        placement: 'top'
      })
    }
  }
}
</script>
