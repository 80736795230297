<style>
  .maxheight {
    max-height: 125px;
  }
  .width-newbooklet {
    width: 148mm;
  }

</style>

<template>
      <div class="bg-white dark:bg-gray-900 rounded-lg shadow py-4 px-6 flex width-newbooklet">
        <div class="flex w-full dark:text-white">
          <div v-if="!game" class="flex flex-col w-full pb-3">
            <div class="text-3xl font-semibold mb-3 text-center">{{$t('new_booklet')}}</div>
            <!-- Search for club -->
            <div class="mt-3" v-if="!club">
              <div class="flex bg-gray-300 dark:bg-gray-700 items-center rounded-lg mb-2">
              <svg class="ml-3 fill-current text-gray-500" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M21.172 24l-7.387-7.387c-1.388.874-3.024 1.387-4.785 1.387-4.971 0-9-4.029-9-9s4.029-9 9-9 9 4.029 9 9c0 1.761-.514 3.398-1.387 4.785l7.387 7.387-2.828 2.828zm-12.172-8c3.859 0 7-3.14 7-7s-3.141-7-7-7-7 3.14-7 7 3.141 7 7 7z"/></svg>
              <input v-model="queryClub" class="pl-3 w-64 placeholder-gray-600 focus:placeholder-gray-500 dark:placeholder-gray-500 dark:focus:placeholder-gray-600" type="text" placeholder="Suche nach deinem Verein...">
              </div>
              <div v-if="clubsFiltered.length" class="list-label maxheight overflow-y-hidden">
                <div v-for="club in clubsFiltered" :key="club.id" @click="clubSelected(club)" class="option first:rounded-t-lg last:rounded-b-lg">{{club.name}}</div>
              </div>
            </div>
            <template v-else>
              <div v-if="!user" class="label flex items-center justify-between">
                <span>{{club.name}}</span>
                <DeleteButton  @onDelete="resetClub()"></DeleteButton>
              </div>
              <!-- Search for team -->
              <v-select class="mb-4 z-50" :title="$t('team')" :options="teams" :value="team" v-on:input="teamSelected" :xl="true">
                <template v-slot:default="prop">
                  {{prop.item ? leagueById(prop.item.league_id).name : '...'}}
                </template>
              </v-select>
              <!-- Search for games -->
              <span class="text-sm font-semibold dark:text-white">{{$t('next_homegames')}}</span>
              <template v-if="!game && gamesFiltered.length > 0">
                <div class="list-label">
                <div v-for="g in gamesFiltered" :key="g.id" @click="gameSelected(g)" class="option first:rounded-t last:rounded-b text-sm" :class="{'active' : game && game.id === g.id}">
                  <div class="flex justify-between">
                    <div>{{g.date}}</div>
                  </div>
                  <div>
                    {{g.teamAway}}
                  </div>
                </div>
                </div>
              </template>
              <div v-else-if="game" class="label flex items-center justify-between">
                <div class="flex flex-col text-sm">
                  <div>{{game.date}}</div>
                  <div>{{game.teamAway}}</div>
                </div>
                <DeleteButton  @onDelete="resetGame()"></DeleteButton>
              </div>
              <div class="text-sm py-3" v-else>
                {{$t('no_next_homegames')}}
              </div>
            </template>
          </div>
          <div v-if="game" class="relative pb-3 flex flex-col w-full">
            <!-- Vorlage -->
            <div v-if="gamesFiltered.length" class="absolute top-0 left-0 cursor-pointer" @click="resetGame()">
              <svg class="h-10 w-8 ml-2 mt-1 p-1 fill-current text-gray-500 transform rotate-180" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"/></svg>
            </div>
            <div class="text-3xl font-semibold mb-3 text-center">{{$t('template_choose')}}</div>
            <div v-if="game" class="text-center font-semibold mb-4">{{game.teamAway}}, {{game.date}} {{game.time}}</div>
            <div v-if="game" class="flex justify-center text-xs font-semibold w-full">
              <div class="w-40 h-48 flex justify-center mr-4 p-2 items-center border-2 cursor-pointer hover:shadow-lg hover:bg-gray-100 rounded text-xl bg-white dark:bg-black dark:border-gray-700 dark:hover:bg-gray-900" @click="$emit('onUpdateMatchheft', [{}])">{{$t('without')}}</div>
              <div class="w-40 h-48 bg-teal-500 flex justify-center p-2 items-center border-2 cursor-pointer hover:shadow-lg hover:bg-teal-400 rounded dark:border-gray-700" @click="loadSample()">
                <svg class="w-20 h-20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fill-rule="evenodd">
                  <path fill="#FFF" fill-rule="nonzero" d="M17.57895 17.61404H13.6842l1.61404-7.57895-4.1579 7.57895H8.7544L7.9649 9.77193l-1.6842 7.8421H4.70174L7.07018 6.47369h4.47368l.54386 6.2807 3.36842-6.2807h4.50877z"/>
                </g>
                </svg>
              </div>
            </div>
            <div>
              <div v-if="isLoggedIn && bookletsPublished.length" class="flex justify-center text-xs text-center flex-wrap gap-2 mt-2">
                <div v-for="(booklet, index) in getBooklets" :key="index" class="">
                  <div class="w-40 h-48 flex flex-col justify-center p-2 items-center border-2 cursor-pointer hover:shadow-lg hover:bg-gray-100 dark:bg-black dark:border-gray-700 dark:hover:bg-gray-900" @click="loadBooklet(bookletsPublished[0].blocks)">
                    <span class="font-semibold">{{booklet.league}}</span>
                    {{formatDate(booklet.game_datetime)}}<br><br>
                    {{booklet.home_team}}<br>
                    vs.<br>
                    {{booklet.away_team}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import dateMixin from '@/mixin/dateMixin.js'
import { mapGetters } from 'vuex'
import DeleteButton from '@/components/DeleteButton.vue'
import VSelect from '@/components/v-select.vue'
import SampleMatchheft from '@/assets/sample.json'

export default {
  name: 'SuNewBooklet',
  mixins: [dateMixin],
  components: {
    DeleteButton,
    'v-select': VSelect
  },
  props: {
  },
  data () {
    return {
      dragItems: [],
      queryClub: '',
      showNewBooklet: false
    }
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      user: 'user',
      club: 'swissunihockey/club',
      clubs: 'swissunihockey/clubs',
      game: 'swissunihockey/game',
      games: 'swissunihockey/games',
      gamesHome: 'swissunihockey/nextHomeGames',
      team: 'swissunihockey/team',
      teams: 'swissunihockey/teams',
      awayTeam: 'swissunihockey/awayTeam',
      leagueById: 'swissunihockey/leagueById',
      bookletByGameId: 'swissunihockey/bookletByGameId',
      bookletsPublished: 'swissunihockey/bookletsPublished',
      bookletsOwn: 'swissunihockey/bookletsOwn'
    }),
    getBooklets () {
      const format = 'YYYY-MM-DD HH:mm:ss'
      var result = this.bookletsOwn
      result = result.splice(0, 6).filter(b => b.su_game_id !== this.game.id)
      return result.sort((a, b) => dayjs(b.game_datetime, format) - dayjs(a.game_datetime, format))
    },
    clubsFiltered () {
      if (this.queryClub.length === 0) {
        return []
      }
      return this.clubs.filter((c) => c.name.toLowerCase().match(`.*${this.queryClub.toLowerCase()}.*`))
    },
    gamesFiltered () {
      if (this.isLoggedIn) {
        return this.gamesHome.slice(0, 3).filter(g => this.bookletByGameId(g.id) === undefined)
      } else {
        return this.gamesHome.slice(0, 3)
      }
    }
  },
  methods: {
    clubSelected (club) {
      this.$store.dispatch('swissunihockey/updateClub', club)
      this.queryClub = ''
      this.$store.dispatch('swissunihockey/fetchTeams')
    },
    gameSelected (game) {
      this.$store.dispatch('swissunihockey/updateGame', game)
    },
    resetClub () {
      this.$store.dispatch('swissunihockey/resetClub')
    },
    resetGame () {
      this.$store.dispatch('swissunihockey/resetGame')
    },
    resetTeam () {
      this.$store.dispatch('swissunihockey/resetTeam')
      this.$emit('onResetTeam')
    },
    async teamSelected (team) {
      this.resetGame()
      await this.$store.dispatch('swissunihockey/updateTeam', team)
      this.$emit('onTeamSelected', team)
    },
    loadBooklet (booklet) {
      var book = JSON.parse(JSON.stringify(booklet))
      book.forEach(book => {
        book.blocks.forEach(block => {
          if (block.data) {
            if (block.data.text) {
              var temp = document.createElement('div')
              temp.innerHTML = block.data.text
              const markers = temp.querySelectorAll('.cdx-template-marker')
              markers.forEach(mark => {
                const value = mark.dataset.template
                if (value === 'HOMETEAM') {
                  mark.innerHTML = this.team.name
                } else if (value === 'AWAYTEAM') {
                  mark.innerHTML = this.awayTeam.name
                } else if (value === 'GAMEPLACE') {
                  mark.innerHTML = this.game.place
                } else if (value === 'GAMETIME') {
                  mark.innerHTML = `${this.game.date}, ${this.game.time}`
                } else if (value === 'LEAGUE') {
                  mark.innerHTML = this.leagueById(this.team.league_id).name
                }
              })
              block.data.text = temp.innerHTML
            }
            if (block.data.team) {
              block.data.team = this.team
            }
            if (block.data.awayTeam) {
              block.data.team = this.awayTeam
            }
            if (block.data.game) {
              block.data.game = this.game
            }
            if (block.type === 'playersTable') {
              block.data.playersHome = null
              block.data.playersAway = null
              block.data.staffHome = null
              block.data.staffAway = null
            }
          }
        })
      })
      this.$emit('onUpdateMatchheft', book)
    },
    loadSample () {
      console.log(this.team, this.awayTeam)
      var db = JSON.stringify(SampleMatchheft)
      db = db.replace('%LEAGUE%', this.leagueById(this.team.league_id).name)
      db = db.replace('%HOMETEAM%', this.team.name).replace('%AWAYTEAM%', this.awayTeam.name)
      db = db.replace('%GAMEPLACE%', this.game.place).replace('%GAMETIME%', `${this.game.date}, ${this.game.time}`)
      this.$emit('onUpdateMatchheft', JSON.parse(db))
    }
  }
}
</script>
