<style>
  .slide-fade-enter-active {
    @apply transition-all duration-500 opacity-100;
  }
  .slide-fade-enter, .slide-fade-leave-to {
    @apply transition-all duration-500 opacity-0;
  }
</style>
<template>
   <transition name="slide-fade">
    <div v-if="show" class="fixed bottom-0 left-0 m-3 bg-green-100 border-l-4 border-green-500 text-green-700 p-4 z-50" role="alert">
      <p class="font-bold">{{$t('saved')}}</p>
       <p>{{message}}</p>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    show: Boolean,
    message: String
  }
}
</script>
